import React from 'react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Button, Tooltip, Menu, Text } from 'components/common/ComponentLibrary';
import { capitalizeFirstLetter } from 'utilities/string.utils';
import { DENSITY } from 'constants/density.constants';
import useDensity from 'hooks/application/useDensity';

const DensitySelector = () => {
  const { setDensity, density } = useDensity();

  const handleSetTheme = async (_density: DENSITY) => {
    await setDensity(_density);
  };

  return (
    <Tooltip
      width='full'
      size='m'
      spacing='none'
      target={
        <Button theme='bare' width='none' size='m'>
          {capitalizeFirstLetter(density)}
          <Button.Icon icon={<ChevronDownIcon className='w-5' />} />
        </Button>
      }
      isInteractive={true}
      hideOnClick={true}
      scope='global'
      trigger='click'
    >
      <Menu>
        <Menu.Item onClick={() => handleSetTheme(DENSITY.DEFAULT)}>
          <Text color='inherit'>{capitalizeFirstLetter(DENSITY.DEFAULT)}</Text>
          {density === DENSITY.DEFAULT && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => handleSetTheme(DENSITY.COMPACT)}>
          <Text color='inherit'>{capitalizeFirstLetter(DENSITY.COMPACT)}</Text>
          {density === DENSITY.COMPACT && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => handleSetTheme(DENSITY.ALL)}>
          <Text color='inherit'>{capitalizeFirstLetter(DENSITY.ALL)}</Text>
          {density === DENSITY.ALL && <CheckIcon className='w-5' />}
        </Menu.Item>
      </Menu>
    </Tooltip>
  );
};

export default DensitySelector;
