export function removeItemAtIndex(array: any[], index: number) {
  if (index > -1 && index < array.length) {
    array.splice(index, 1);
  }

  return array;
}

export function uniquifyArray(array: string[]) {
  return [...new Set(array)];
}
