import React from 'react';
import { useField } from 'formik';
import MapTile from 'components/common/Maps/MapTile';

const DumpEditLocation = () => {
  const [field, meta, helpers] = useField('location');
  if (!field.value) {
    return null;
  }

  return (
    <MapTile location={[field.value.latitude, field.value.longitude]} onRemove={() => helpers.setValue(undefined)} />
  );
};

export default DumpEditLocation;
