import { useState, useEffect, useCallback, DragEvent } from 'react';
import { validateFile } from 'utilities/common/file.utils';
import { MAX_FILE_SIZE } from 'constants/attachment.constants';
import { ALLOWED_FILE_EXTENSIONS } from 'constants/file.constants';

interface IUseFileDragger {
  contextRef: any;
  onFilesSuccessfullyDropped: (arg0: Array<File>) => any;
  onFilesRejected: (errors: ({ name: string } | undefined)[]) => any;
}

let draggingCount = 0;

export const useFileDragger = ({
  contextRef,
  onFilesSuccessfullyDropped,
  onFilesRejected,
}: IUseFileDragger): boolean => {
  const [dragging, setDragging] = useState(false);

  const handleDragIn = useCallback((ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    draggingCount++;
    if (ev.dataTransfer.items && ev.dataTransfer.items.length !== 0) {
      setDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    draggingCount--;
    if (draggingCount > 0) return;
    setDragging(false);
  }, []);

  const handleDrag = useCallback((ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    async (ev: DragEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      setDragging(false);
      draggingCount = 0;

      const eventFiles = ev.dataTransfer.files;
      if (eventFiles && eventFiles.length > 0) {
        const files = Array.from(eventFiles);

        const validatedFileErrors = await Promise.all(
          files.map((file) => validateFile(file, { maxSize: MAX_FILE_SIZE, allowedTypes: ALLOWED_FILE_EXTENSIONS })),
        );
        if (validatedFileErrors.filter(Boolean).length > 0) {
          onFilesRejected(validatedFileErrors);
          return;
        }

        onFilesSuccessfullyDropped(files);
      }
    },
    [onFilesSuccessfullyDropped],
  );

  useEffect(() => {
    const ele = contextRef.current;
    ele.addEventListener('dragenter', handleDragIn);
    ele.addEventListener('dragleave', handleDragOut);
    ele.addEventListener('dragover', handleDrag);
    ele.addEventListener('drop', handleDrop);
    return () => {
      ele.removeEventListener('dragenter', handleDragIn);
      ele.removeEventListener('dragleave', handleDragOut);
      ele.removeEventListener('dragover', handleDrag);
      ele.removeEventListener('drop', handleDrop);
    };
  }, [handleDragIn, handleDragOut, handleDrag, handleDrop, contextRef]);

  return dragging;
};
