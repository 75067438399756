import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useAuthStore } from 'store/auth.store';
import AccountScreen from './account/Account.screen';
import DumpDetailScreen from './dump-detail/DumpDetail.screen';
import DumpListScreen from './dump-list/DumpList.screen';
import EnterDumpScreen from './enter-dump/EnterDump.screen';
import LandingPageScreen from './landing/LandingPage.screen';
import PageNotFound from './not-found/PageNotFound.screen';
import SettingsScreen from './settings/Settings.screen';
import VerifyEmailPage from './verify-email/VerifyEmailPage.screen';
import ImpressumScreen from './impressum/Impressum.screen';
import LostPasswordPage from './lost-password/LostPasswordPage.screen';
import ResetPasswordPage from './reset-password/ResetPasswordPage.screen';
import useTrackPageView from 'hooks/application/useTrackPageView';
import DeviceVerificationScreen from './device-verification/DeviceVerification.screen';

const AnimatedRouter = () => {
  const location = useLocation();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const protectedPage = (page: JSX.Element) => (isAuthenticated ? page : <LandingPageScreen />);

  useTrackPageView(isAuthenticated);

  return (
    <AnimatePresence mode='sync'>
      <Routes key={location.pathname} location={location}>
        <Route path='/' element={protectedPage(<EnterDumpScreen />)} />
        <Route path='/dumps' element={protectedPage(<DumpListScreen />)} />
        <Route path='/dumps/:dumpId' element={protectedPage(<DumpDetailScreen />)} />
        <Route path='/settings' element={protectedPage(<SettingsScreen />)} />
        <Route path='/account' element={protectedPage(<AccountScreen />)} />
        <Route path='/verify-email' element={<VerifyEmailPage />} />
        <Route path='/lost-password' element={<LostPasswordPage />} />
        <Route path='/reset-password' element={protectedPage(<ResetPasswordPage />)} />
        <Route path='/impressum' element={<ImpressumScreen />} />
        <Route path='/verify-device' element={<DeviceVerificationScreen />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRouter;
