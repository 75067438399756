import { useState } from 'react';
import { getCurrentLocation } from 'utilities/location.utils';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { useAuthStore } from 'store/auth.store';
import { LocationInterface } from 'models/dumps/interfaces/LocationInterface';
import { v4 as uuidv4 } from 'uuid';
import { createDump } from 'models/dumps/services/rxDb/RxDbDumpService';
import { Dump } from 'models/dumps/entities/Dump';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { isLocationEnabled } from 'models/application/services/LocalStorageService';
import {
  createDumpAudioAttachment,
  createDumpImageAttachment,
  createDumpVideoAttachment,
} from 'utilities/dumps/dumpAttachment.utils';
import { IDraftAttachment } from 'store/dumpDraft.store';
import { isFilePathImage, isFilePathVideo } from 'utilities/storage.utils';

const useCreateDump = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const handleCreateDump = async (
    text: string,
    location?: LocationInterface | null,
    attachments?: IDraftAttachment[],
    audio?: File[],
  ) => {
    if (!currentUser) {
      return;
    }

    setIsBusy(true);

    const newDump = new Dump({
      id: uuidv4().toString(),
      text,
      location,
      user_id: currentUser.id,
      created_at: getCurrentUTCDateFormatted(),
      updated_at: getCurrentUTCDateFormatted(),
    });

    if (isLocationEnabled() && !newDump.location) {
      const currentLocation = await getCurrentLocation();
      if (currentLocation) {
        newDump.location = currentLocation;
      }
    }

    await createDump(newDump);

    if (attachments && attachments.length > 0) {
      await Promise.all(
        attachments?.map(async (attachment: IDraftAttachment) => {
          if (isFilePathVideo(attachment.file.name)) {
            await createDumpVideoAttachment(newDump.id, attachment.file, currentUser.id);
          } else {
            await createDumpImageAttachment(newDump.id, attachment.file, currentUser.id);
          }
        }),
      );
    }

    if (audio && audio.length > 0) {
      await Promise.all(
        audio?.map(async (audioBlob: File) => {
          await createDumpAudioAttachment(newDump.id, audioBlob, currentUser.id);
        }),
      );
    }

    trackEvent(TRACKING_EVENT_ENUM.DUMP_CREATED, {
      hasAttachments: (attachments && attachments?.length > 0) || (audio && audio?.length > 0),
      hasLocation: !!newDump.location,
      hasImage: attachments && attachments.some((attachment) => isFilePathImage(attachment.file.name)),
      hasVideo: attachments && attachments.some((attachment) => isFilePathVideo(attachment.file.name)),
      hasAudio: audio && audio.length > 0,
    });

    setIsBusy(false);
  };

  return {
    createDump: handleCreateDump,
    isBusy,
  };
};

export default useCreateDump;
