import { Button, Text } from 'components/common/ComponentLibrary';
import useCreateDump from 'hooks/dumps/useCreateDump';
import React from 'react';

type Props = { searchTerm: string };

const NoSearchMatchesComponent: React.FC<Props> = (props) => {
  const { searchTerm } = props;

  const handleCreateDump = () => {
    createDump(searchTerm);
  };

  const { createDump } = useCreateDump();

  return (
    <div className='flex flex-col flex-1 h-full justify-center items-center'>
      <div className='flex flex-col items-center text-lg text-dark dark:text-white gap-2'>
        <Text textAlign='center' size='l'>
          No matches found for:
        </Text>
        <Text fontWeight='bold' textAlign='center' size='xl'>
          {searchTerm}
        </Text>
      </div>
      <div className='mt-10'>
        <Button size='s' onClick={handleCreateDump}>
          Dump it!
        </Button>
      </div>
    </div>
  );
};

export default NoSearchMatchesComponent;
