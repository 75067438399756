import React, { ComponentPropsWithoutRef } from 'react';
import { orderBy } from 'lodash';
import TagItemComponent from './TagItem.component';

type Props = {
  tags: string[];
  maxElements?: number;
  searchTerm: string;
  onToggleTag: (searchTerm: string) => void;
} & ComponentPropsWithoutRef<'ul'>;

const TagListComponent: React.FC<Props> = (props) => {
  const { tags, maxElements, searchTerm, onToggleTag, className = '', ...ulProps } = props;

  const visibleTags = (() => {
    if (maxElements) {
      if (searchTerm && !searchTerm.startsWith('#')) {
        return orderBy(tags, (tag) => (tag.indexOf(searchTerm) >= 0 ? 1 : -1), 'desc').slice(
          0,
          Math.min(tags.length, maxElements),
        );
      }
      return tags.slice(0, Math.min(tags.length, maxElements));
    }
    return tags;
  })();

  return (
    <ul
      className={`flex ${className} pb-3 overflow-x-auto overflow-y-hidden gap-x-2 text-xs text-dark dark:text-bright dumpit-scrollbar`}
      {...ulProps}
    >
      {visibleTags.map((tag) => (
        <TagItemComponent key={tag} tag={tag} searchTerm={searchTerm} onToggleTag={onToggleTag} />
      ))}
    </ul>
  );
};

export default TagListComponent;
