import { RxDocument } from 'rxdb';
import { ProfileDocumentType } from './profile.schema';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { getPublicUrl } from 'utilities/storage.utils';

export type ProfileDocumentMethods = {
  asProfile: () => ProfileInterface;
};
export type ProfileDocument = RxDocument<ProfileDocumentType, ProfileDocumentMethods>;

export const defaultProfileDocumentMethods: ProfileDocumentMethods = {
  asProfile: function (this: ProfileDocument) {
    return {
      id: this.id,
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      image_path: this.image_path
        ? `${getPublicUrl(this.image_path, STORAGE_BUCKET_ENUM.AVATARS)}?lastmod=${this.updated_at}`
        : '',
      phone: this.phone,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  },
};
