import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import { captureException } from '@sentry/react';

export async function getVideoThumbnail(videoFile: File) {
  try {
    const result = await generateVideoThumbnails(videoFile, 1, 'file');
    return result?.[0];
  } catch (error) {
    captureException(error);
    return null;
  }
}

export async function getVideoDuration(file: File): Promise<number> {
  try {
    return await new Promise((resolve) => {
      const video = document.createElement('video');

      video.src = URL.createObjectURL(file);

      video.addEventListener(
        'loadedmetadata',
        function () {
          const duration = video.duration;

          resolve(duration);

          URL.revokeObjectURL(video.src);
        },
        false,
      );

      video.onerror = function () {
        resolve(0);
      };
    });
  } catch (error) {
    return 0;
  }
}

export function formatSecondsToHoursMinutes(secondsIn: number): string {
  const hours = Math.floor(secondsIn / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((secondsIn % 3600) / 60);
  const seconds = Math.floor(secondsIn % 60);

  return `${hours === '00' ? '' : `${hours}:`}${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}
