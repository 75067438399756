import TagListComponent from 'components/dump-list/item/TagList.component';
import { IDump } from 'models/dump.model';
import React, { ComponentPropsWithoutRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IRoute } from 'screens/routes';
import { getUrlDumps } from 'utilities/urls.utils';
import { useSearchStore } from 'store/search.store';

type Props = {
  dump: IDump;
} & ComponentPropsWithoutRef<'div'>;

const DumpTags: React.FC<Props> = (props) => {
  const { dump } = props;
  const navigate = useNavigate();

  const toggleTag = useSearchStore((state) => state.toggleTag);
  return (
    <div className='flex items-center mb-2'>
      {dump?.tags && dump?.tags?.length > 0 && (
        <TagListComponent
          tags={dump.tags}
          searchTerm=''
          onToggleTag={(tag) => {
            toggleTag(tag);
            navigate(getUrlDumps(), {
              state: { previousRoute: 'dump_detail' as IRoute },
            });
          }}
          className='gap-x-1'
        />
      )}
    </div>
  );
};

export default DumpTags;
