import React, { useState } from 'react';

import { DumpLocationAction } from './Actions';
import { Button, Tooltip } from 'components/common/ComponentLibrary';
import { CameraIcon, MapPinIcon, MicrophoneIcon, PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { LocationInterface } from 'models/dumps/interfaces/LocationInterface';
import useNetworkStatus from 'hooks/application/useNetworkStatus';
import { useHotkeys } from 'react-hotkeys-hook';

interface IDumpActions {
  onLocation: (location: LocationInterface | null) => void;
  showLocationAction?: boolean;
  onImageUpload?: () => void;
  onRecord?: () => void;
}
const DumpActions = ({ onLocation, showLocationAction = true, onImageUpload, onRecord }: IDumpActions) => {
  const { isOnline } = useNetworkStatus();
  const [targetIcon, setTargetIcon] = useState(<PaperClipIcon className='w-8' />);

  useHotkeys('meta+i', () => onImageUpload && onImageUpload(), {
    enableOnFormTags: true,
    preventDefault: true,
  });
  useHotkeys('meta+r', () => onRecord && onRecord(), {
    enableOnFormTags: true,
    preventDefault: true,
  });

  return (
    <Tooltip
      width='full'
      size='none'
      spacing='none'
      target={
        <Button theme='bare' width='none' size='l'>
          <Button.Icon icon={targetIcon} />
        </Button>
      }
      isInteractive={true}
      isPlain={true}
      scope='local'
      trigger='click'
      placement='top'
      onShow={() => setTargetIcon(<XMarkIcon className='w-8' />)}
      onHidden={() => setTargetIcon(<PaperClipIcon className='w-8' />)}
    >
      <div className='flex flex-1 flex-col items-center justify-end gap-2 w-full'>
        {showLocationAction && (
          <DumpLocationAction onLocation={onLocation}>
            {({ getLocation }: { getLocation: () => void }) => (
              <Button width='none' size='m' theme='bare' onClick={getLocation}>
                <Button.Icon icon={<MapPinIcon className='w-7' />} />
              </Button>
            )}
          </DumpLocationAction>
        )}
        {onImageUpload && (
          <Button width='none' size='m' theme='bare' onClick={onImageUpload} status={!isOnline ? 'disabled' : ''}>
            <Button.Icon icon={<CameraIcon className='w-7' />} />
          </Button>
        )}
        {onRecord && (
          <Button width='none' size='m' theme='bare' onClick={onRecord} status={!isOnline ? 'disabled' : ''}>
            <Button.Icon icon={<MicrophoneIcon className='w-7' />} />
          </Button>
        )}
      </div>
    </Tooltip>
  );
};

export default DumpActions;
