import { ReactNode, useEffect, useState } from 'react';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { getSignedUrl } from 'utilities/storage.utils';

interface ISupabaseVideoAttachment {
  children: (props: { src: string; previewSrc?: string | null }) => ReactNode;
  path: string;
  storageBucket: STORAGE_BUCKET_ENUM;
}

const SupabaseVideoAttachment = ({ children, path, storageBucket }: ISupabaseVideoAttachment) => {
  const [src, setSrc] = useState<string | null>(null);
  const [previewSrc, setPreviewSrc] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const signedUrl = await getSignedUrl(path, storageBucket);
      if (signedUrl) {
        setSrc(signedUrl);
      }

      const signedPreviewUrl = await getSignedUrl(path.replace('.mp4', '.jpeg'), storageBucket);
      if (signedPreviewUrl) {
        setPreviewSrc(signedPreviewUrl);
      }
    })();
  }, []);
  if (!src) {
    return null;
  }

  return children?.({
    src,
    previewSrc,
  });
};

export default SupabaseVideoAttachment;
