import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import {
  FIRSTNAME_MAX_LENGTH,
  FIRSTNAME_MIN_LENGTH,
  LASTNAME_MAX_LENGTH,
  LASTNAME_MIN_LENGTH,
} from 'constants/profiles.constants';
import { useProfileStore } from 'store/profile.store';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .min(FIRSTNAME_MIN_LENGTH, `Firstname should be at least ${FIRSTNAME_MIN_LENGTH} characters`)
    .max(FIRSTNAME_MAX_LENGTH, `Firstname should be at most ${FIRSTNAME_MAX_LENGTH} characters`)
    .required('Enter your first name'),
  lastname: yup
    .string()
    .trim()
    .min(LASTNAME_MIN_LENGTH, `Lastname should be at least ${LASTNAME_MIN_LENGTH} characters`)
    .max(LASTNAME_MAX_LENGTH, `Lastname should be at most ${LASTNAME_MAX_LENGTH} characters`)
    .required('Enter your last name'),
});

const ProfileNameForm = ({ onChanged }: { onChanged: () => void }) => {
  const { t } = useTranslation('translations');
  const [profile, updateProfile] = useProfileStore((state) => [state.profile, state.updateProfile]);

  return (
    <Formik
      onSubmit={async ({ firstname, lastname }) => {
        await updateProfile({ first_name: firstname, last_name: lastname });
        onChanged();
      }}
      initialValues={{ firstname: profile?.asProfile().first_name, lastname: profile?.asProfile().last_name }}
      validateOnBlur={true}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-6 w-full'>
            <FormikInputField name='firstname' placeholder='Your first name' type='text' />
            <FormikInputField name='lastname' placeholder='Your last name' type='text' />
            <div className='flex justify-between'>
              <Button size='m' onClick={onChanged} theme='ghost'>
                {t('nameModal.rejectAction')}
              </Button>
              <Button size='m' type='submit' status={isSubmitting ? 'busy' : ''}>
                {t('nameModal.acceptAction')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileNameForm;
