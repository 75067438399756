import React from 'react';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { IDump } from 'models/dump.model';
import { Text } from 'components/common/ComponentLibrary';
import { DumpRow } from 'components/dumps/DumpList';

interface IListSectionComponent {
  searchTerm: string;
  title: string;
  dumps: IDump[];
}

const ListSectionComponent = ({ title, searchTerm, dumps }: IListSectionComponent) => (
  <div className={classNames('bg-transparent', 'list-container')}>
    <Text color='gray-500' transform='uppercase' size='m' spacing='s' tag='div'>
      {title} ({dumps.length})
    </Text>
    <ul className={`dump-list`}>
      {orderBy(dumps, [(dump) => dump.last_edited_at ?? dump.created_at, 'created_at'], ['desc', 'desc']).map(
        (dump) => (
          <DumpRow key={dump.id} dump={dump} className='dump-list-row' searchTerm={searchTerm} />
        ),
      )}
    </ul>
  </div>
);

export default ListSectionComponent;
