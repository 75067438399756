import { ReactNode, useEffect } from 'react';
import useGeolocation from 'hooks/application/useGeolocation';
import { LocationInterface } from 'models/dumps/interfaces/LocationInterface';

interface IDumpCopyAction {
  onLocation: (location: LocationInterface | null) => void;
  children: (props: { getLocation: () => void; hasAccess: boolean }) => ReactNode;
}

const DumpLocationAction = ({ children, onLocation }: IDumpCopyAction) => {
  const { location, getLocation, hasAccess } = useGeolocation();

  const handleGetLocation = () => {
    getLocation();
  };

  useEffect(() => {
    if (location) {
      onLocation(location);
    }
  }, [location]);

  return children?.({
    getLocation: handleGetLocation,
    hasAccess,
  });
};

export default DumpLocationAction;
