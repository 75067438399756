import React, { useRef, useState } from 'react';
import { Button } from 'components/common/ComponentLibrary';
import { useHotkeys } from 'react-hotkeys-hook';
import MapTile from 'components/common/Maps/MapTile';
import DumpActions from '../DumpActions';
import { toast } from 'react-toastify';
import useDumps from 'hooks/dumps/useDumps';
import { AnimatePresence } from 'framer-motion';
import DumpCreatedNotification from 'components/notifications/DumpCreatedNotification';
import { ImagePreview, AudioPreview } from 'components/dumps/Attachments';
import { useFilePicker } from 'use-file-picker';
import useCreateDump from 'hooks/dumps/useCreateDump';
import { useDumpDraftStore } from 'store/dumpDraft.store';
import { AudioRecorder } from 'components/common/AudioRecorder';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import { isLocationEnabled } from 'models/application/services/LocalStorageService';
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from 'use-file-picker/validators';
import {
  MAX_ATTACHMENTS_ALLOWED,
  MAX_ATTACHMENTS_REACHED_MESSAGE,
  MAX_FILE_SIZE,
} from 'constants/attachment.constants';
import { removeItemAtIndex } from 'utilities/array.utils';
import { PLACEHOLDER_TEXT } from 'constants/dumps.constants';
import { useSettingsStore } from 'store/setting.store';
import { DumpTextarea } from 'components/common/DumpTextarea';
import { getDraftAttachmentsFromFiles, handleFilesRejected } from 'utilities/dumps/dumpAttachment.utils';
import { useFileDragger } from 'hooks/common/useFileDragger';
import { ALLOWED_FILE_EXTENSIONS } from 'constants/file.constants';
import { useFilePaste } from 'hooks/common/useFilePaste';
import { checkMicrophonePermission } from 'utilities/audio.utils';
import { captureMessage } from '@sentry/react';

const DumpCreate = () => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [showDumpNotification, setShowDumpNotification] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const { dumps } = useDumps();

  const [settings] = useSettingsStore((state) => [state.settings]);

  const [
    dumpDraftText,
    dumpDraftLocation,
    dumpDraftAudio,
    dumpDraftAttachments,
    setDumpDraftText,
    setDumpDraftLocation,
    setDumpDraftAudio,
    setDumpDraftAttachments,
    resetDumpDraftStore,
    isDumpDraftEmpty,
  ] = useDumpDraftStore((state) => [
    state.text,
    state.location,
    state.audio,
    state.attachments,
    state.setText,
    state.setLocation,
    state.setAudio,
    state.setAttachments,
    state.resetStore,
    state.isEmpty,
  ]);

  const { createDump } = useCreateDump();

  const addDump = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    createDump(dumpDraftText, dumpDraftLocation, dumpDraftAttachments, dumpDraftAudio);

    resetDumpDraftStore();
    textareaRef.current?.focus();
    setShowDumpNotification(true);
    setTimeout(
      () => {
        setShowDumpNotification(false);
      },
      dumps?.length === 0 ? 2500 : 1500,
    );

    setIsSubmitting(false);
  };

  useHotkeys(
    'meta+enter',
    addDump,
    {
      enableOnFormTags: true,
    },
    [dumpDraftText],
  );

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: ALLOWED_FILE_EXTENSIONS.map((extension) => `.${extension}`),
    multiple: true,
    validators: [
      new FileAmountLimitValidator({ max: 3 }),
      new FileTypeValidator(ALLOWED_FILE_EXTENSIONS),
      new FileSizeValidator({ maxFileSize: MAX_FILE_SIZE }),
    ],
    onFilesSuccessfullySelected: async (data) => {
      if (data.plainFiles.length + dumpDraftAttachments.length > MAX_ATTACHMENTS_ALLOWED) {
        toast.info(MAX_ATTACHMENTS_REACHED_MESSAGE);
        return;
      }

      const newAttachments = await getDraftAttachmentsFromFiles(data.plainFiles);
      setDumpDraftAttachments([...dumpDraftAttachments, ...newAttachments]);
    },
    onFilesRejected: handleFilesRejected,
  });

  const draggingContextRef = useRef(null);
  useFileDragger({
    contextRef: draggingContextRef,
    onFilesSuccessfullyDropped: async (droppedFiles) => {
      if (droppedFiles.length + dumpDraftAttachments.length > MAX_ATTACHMENTS_ALLOWED) {
        toast.info(MAX_ATTACHMENTS_REACHED_MESSAGE);
        return;
      }

      const newAttachments = await getDraftAttachmentsFromFiles(droppedFiles);
      setDumpDraftAttachments([...dumpDraftAttachments, ...newAttachments]);
    },
    onFilesRejected: handleFilesRejected,
  });

  useFilePaste({
    onFileSuccessfullyPasted: async (_file) => {
      if (dumpDraftAttachments.length + 1 > MAX_ATTACHMENTS_ALLOWED) {
        toast.info(MAX_ATTACHMENTS_REACHED_MESSAGE);
        return;
      }

      const newAttachments = await getDraftAttachmentsFromFiles([_file]);
      setDumpDraftAttachments([...dumpDraftAttachments, ...newAttachments]);
    },
    onFileRejected: handleFilesRejected,
  });

  return (
    <div
      ref={draggingContextRef}
      className='flex flex-1 flex-col md:justify-end md:items-center md:bg-bright md:dark:bg-dark'
    >
      <div className='relative w-full flex flex-1 flex-col md:dark:bg-paperdark md:border-1 md:border-t-1 border-gray-200 md:p-5 dark:border-bright/25 md:bg-white rounded-xl'>
        <DumpTextarea
          name='dumpText'
          value={dumpDraftText}
          onChange={(e, newValue, newPlainTextValue) => {
            setDumpDraftText(newPlainTextValue);
          }}
          textareaRef={textareaRef}
          placeholder={settings?.asSettings()?.show_placeholder ? PLACEHOLDER_TEXT : ''}
        />

        <div className='flex flex-row space-x-2'>
          {dumpDraftLocation?.latitude && dumpDraftLocation?.longitude && (
            <div className='flex'>
              <MapTile
                location={[dumpDraftLocation.latitude, dumpDraftLocation.longitude]}
                onRemove={() => setDumpDraftLocation(undefined)}
              />
            </div>
          )}
          {dumpDraftAttachments?.map((attachment, index) => (
            <ImagePreview
              key={index}
              src={attachment.src || ''}
              previewSrc={attachment.src || ''}
              onRemove={() => setDumpDraftAttachments(removeItemAtIndex(dumpDraftAttachments, index))}
            />
          ))}
          {dumpDraftAudio?.map((audioBlob, index) => (
            <AudioPreview
              key={index}
              src={audioBlob}
              onRemove={() => {
                setDumpDraftAudio(dumpDraftAudio.filter((_, currentIndex) => index !== currentIndex));
              }}
            />
          ))}
        </div>

        <div className='absolute right-4 bottom-2'>
          {isRecording && (
            <MotionEffectPopIn>
              <AudioRecorder
                onDone={() => setIsRecording(false)}
                onSave={async (audio) => {
                  if (dumpDraftAttachments.length + dumpDraftAudio.length === MAX_ATTACHMENTS_ALLOWED) {
                    toast.info(MAX_ATTACHMENTS_REACHED_MESSAGE);
                    return;
                  }

                  setDumpDraftAudio([...dumpDraftAudio, audio]);
                }}
              />
            </MotionEffectPopIn>
          )}
          {!isRecording && (
            <DumpActions
              onImageUpload={() => {
                if (dumpDraftAttachments.length >= MAX_ATTACHMENTS_ALLOWED) {
                  toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
                  captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
                  return;
                }

                openFilePicker();
              }}
              showLocationAction={isLocationEnabled()}
              onLocation={(location: any) => {
                if (dumpDraftLocation) {
                  setDumpDraftLocation(undefined);
                  toast.info('Location removed');
                  return;
                }

                setDumpDraftLocation(location);
                toast.info('Location added');
              }}
              onRecord={async () => {
                const hasPermission = await checkMicrophonePermission();
                if (!hasPermission) {
                  return;
                }

                setIsRecording(true);
              }}
            />
          )}
        </div>
      </div>

      <div className='flex items-center justify-center w-full mt-5'>
        <Button size='l' width='full' onClick={addDump} status={isSubmitting || isDumpDraftEmpty() ? 'disabled' : ''}>
          Dump it!
        </Button>
      </div>

      <AnimatePresence>
        {showDumpNotification && <DumpCreatedNotification isFirstDump={dumps?.length <= 1} />}
      </AnimatePresence>
    </div>
  );
};

export default DumpCreate;
