import React from 'react';
import { AudioPreview, ImagePreview, VideoPreview } from '../Attachments';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { FieldArray, useField } from 'formik';
import SupabaseAttachment from 'components/common/SupabaseAttachment';
import { isFilePathAudio, isFilePathImage, isFilePathVideo } from 'utilities/storage.utils';
import SupabaseVideoAttachment from 'components/common/SupabaseVideoAttachment';
import { IDraftAttachment } from 'store/dumpDraft.store';
import SupabaseImageAttachment from 'components/common/SupabaseImageAttachment';

const DumpEditAttachments = () => {
  const [field] = useField('attachments');

  if (!field.value || field.value.length === 0) {
    return null;
  }

  return (
    <FieldArray
      name='attachments'
      render={(arrayHelpers) => (
        <div className='flex space-x-2'>
          {field.value &&
            field.value.length > 0 &&
            field.value.map((attachment: AttachmentInterface & IDraftAttachment, index: number) => {
              if (isFilePathImage(attachment.file_path)) {
                return (
                  <SupabaseImageAttachment
                    key={attachment.id}
                    path={attachment.file_path}
                    storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                  >
                    {({ previewSrc, src }) => (
                      <ImagePreview
                        src={src || previewSrc}
                        previewSrc={previewSrc || src}
                        onRemove={() => arrayHelpers.remove(index)}
                      />
                    )}
                  </SupabaseImageAttachment>
                );
              } else if (isFilePathVideo(attachment.file_path)) {
                return (
                  <SupabaseVideoAttachment
                    key={attachment.id}
                    path={attachment.file_path}
                    storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                  >
                    {({ src, previewSrc }) => (
                      <VideoPreview
                        src={src}
                        previewSrc={previewSrc}
                        onRemove={() => arrayHelpers.remove(index)}
                        duration={attachment.duration}
                      />
                    )}
                  </SupabaseVideoAttachment>
                );
              } else if (isFilePathAudio(attachment.file_path)) {
                return (
                  <SupabaseAttachment
                    key={attachment.id}
                    path={attachment.file_path}
                    storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                  >
                    {({ src }) => (
                      <AudioPreview
                        src={src}
                        onRemove={() => arrayHelpers.remove(index)}
                        duration={attachment.duration}
                      />
                    )}
                  </SupabaseAttachment>
                );
              } else if (attachment.file && isFilePathImage(attachment.file.name)) {
                return (
                  <ImagePreview
                    key={attachment.src}
                    src={attachment.src as string}
                    previewSrc={attachment.src as string}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                );
              } else if (attachment.file && isFilePathVideo(attachment.file.name)) {
                return (
                  <VideoPreview
                    key={attachment.src}
                    src={attachment.src as string}
                    previewSrc={attachment.src}
                    onRemove={() => arrayHelpers.remove(index)}
                    duration={attachment.duration}
                  />
                );
              } else if (attachment.file && isFilePathAudio(attachment.file.name)) {
                return (
                  <AudioPreview
                    key={attachment.src}
                    src={attachment.src as string}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                );
              }
            })}
        </div>
      )}
    />
  );
};

export default DumpEditAttachments;
