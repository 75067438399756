import React, { useEffect } from 'react';
import { Hyperlink, Text } from 'components/common/ComponentLibrary';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { DumpItIcon } from 'components/common/SpecialIcons';
import Footer from 'components/application/Footer';
import supabase from 'store/supabase/supabase';
import { toast } from 'react-toastify';

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useDocumentTitle('Verify email');

  useEffect(() => {
    if (!searchParams.get('email')) {
      navigate(getUrlHome());
      return;
    }
  }, []);

  const handleResend = async () => {
    toast.success('Check your inbox to confirm your email!');

    await supabase.auth.resend({
      type: 'signup',
      email: searchParams.get('email') as string,
    });
  };

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <div className='flex items-center text-center justify-center mt-5 mb-10 md:mb-32'>
        <Hyperlink href={getUrlHome()}>
          <DumpItIcon className='w-20 aspect-square' />
        </Hyperlink>
      </div>
      <div className='m-auto max-w-lg px-4 md:px-0 flex-1'>
        <div className='space-y-4'>
          <Text textAlign='center' size='xxl' fontWeight='bold'>
            Confirm your email
          </Text>
          <Text textAlign='center' size='l'>
            Check your inbox (and spam folder) at{' '}
            <strong>{decodeURIComponent(searchParams.get('email') as string)}</strong> to verify your email address and
            activate your account.
          </Text>
          <Text textAlign='center' size='l'>
            If you didn't receive the email,{' '}
            <a className='underline cursor-pointer' onClick={handleResend}>
              click here
            </a>{' '}
            to resend it.
          </Text>
        </div>
      </div>
      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default VerifyEmailPage;
