import React, { useEffect } from 'react';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';

const DeviceVerificationScreen = () => {
  useDocumentTitle('Complete device verification');
  const [params] = useSearchParams();
  const nonce = params.get('n');
  const pubKey = params.get('d');
  const mobileVerificationLink = `app.dumpit://verify-device?n=${nonce}&d=${pubKey}`;

  useEffect(() => {
    if (isMobile) {
      window.location.replace(mobileVerificationLink);
    }
  }, [isMobile, nonce, pubKey]);

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      {(!nonce || !pubKey) && (
        <div className='flex items-center text-center justify-center mt-5 mb-10 md:mb-32'>
          <span>Invalid verification link.</span>
        </div>
      )}

      {nonce && pubKey && (
        <>
          <div className='flex flex-col items-center text-center justify-center mt-5 mb-10 md:mb-32'>
            <h1>Device Verification</h1>
            {isMobile && <span>mobile</span>}
          </div>
          <div className='m-auto max-w-sm px-4 md:px-0 flex-1'>
            <div className='space-y-4'>
              <BrowserView>
                <p>
                  Public Key:
                  <br />
                  {pubKey}
                </p>
                <p>
                  Nonce:
                  <br />
                  {nonce}
                </p>
              </BrowserView>
              <MobileView>
                <div className='flex flex-col justify-center items-center'>
                  <h1>Redirecting ...</h1>
                  <p>
                    Click{' '}
                    <a href={mobileVerificationLink} className='text-cyan'>
                      here
                    </a>{' '}
                    if you're not being redirect.
                  </p>
                </div>
              </MobileView>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceVerificationScreen;
