import React, { useEffect, useState } from 'react';
import { Toggle } from 'components/common/ComponentLibrary';
import useGeolocation from 'hooks/application/useGeolocation';
import { isLocationEnabled, toggleLocationEnabled } from 'models/application/services/LocalStorageService';

const ToggleEnableLocation = () => {
  const [isOn, setIsOn] = useState(isLocationEnabled());
  const { getLocation, hasAccess } = useGeolocation();

  useEffect(() => {
    if (isLocationEnabled() && hasAccess) {
      getLocation();
    }
  }, []);

  return (
    <Toggle
      size='l'
      name='enableLocation'
      value={isOn && hasAccess}
      onChange={async (event) => {
        if (event.target.checked) {
          getLocation();
        }
        toggleLocationEnabled(!isOn);
        setIsOn(!isOn);
      }}
    />
  );
};

export default ToggleEnableLocation;
