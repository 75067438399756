import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

export const tagSchemaLiteral = {
  title: 'dumpit! tag schema',
  version: 1,
  description: 'Represents a tag',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      minLength: 36,
      maxLength: 36,
    },
    name: {
      type: 'string',
    },
    dump_id: {
      type: 'string',
    },
    created_at: {
      type: 'string',
      final: true,
    },
    updated_at: {
      type: 'string',
    },
  },
  required: ['id', 'name', 'dump_id'],
} as const;

const schemaTyped = toTypedRxJsonSchema(tagSchemaLiteral);
export type TagDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const tagSchema: RxJsonSchema<TagDocumentType> = tagSchemaLiteral;
