import { useSettingsStore } from 'store/setting.store';
import { PLAYSPEED } from 'constants/playspeed.constants';

const usePlayspeed = () => {
  const [settings, updateSettings] = useSettingsStore((state) => [state.settings, state.updateSettings]);

  const handleSetPlayspeed = async (playspeed: PLAYSPEED) => {
    await updateSettings({ playspeed });
  };

  return { setPlayspeed: handleSetPlayspeed, playspeed: settings?.asSettings()?.playspeed || PLAYSPEED.NORMAL };
};

export default usePlayspeed;
