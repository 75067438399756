import { dumpitDatabase } from 'store/rxdb/dumpit.database';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { getFileExtension } from 'utilities/common/file.utils';

export function getFilePath(userId: string, dumpId: string, id: string, name: string, postfix = '') {
  return `${userId}/${dumpId}/${id}${postfix}.${getFileExtension(name)}`;
}

export async function createAttachment(
  name: string,
  dumpId: string,
  userId: string,
  duration?: number,
): Promise<AttachmentInterface> {
  const id = uuidv4().toString();
  const newAttachment: AttachmentInterface = {
    id,
    filename: name,
    duration: duration || 0,
    playtime: 0,
    file_path: getFilePath(userId, dumpId, id, name),
    dump_id: dumpId,
    user_id: userId,
    _deleted: false,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
  };

  return await dumpitDatabase.attachments.insert(newAttachment);
}

export async function deleteAttachment(attachmentId: string) {
  try {
    const dumpDoc = await dumpitDatabase.attachments.findOne({ selector: { id: attachmentId } }).exec();
    if (!dumpDoc) return Promise.reject('No attachment found');

    await dumpDoc?.update({
      $set: {
        _deleted: true,
        updated_at: getCurrentUTCDateFormatted(),
      },
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAttachmentsByDumpId(dumpId: string) {
  try {
    return await dumpitDatabase.attachments.queryAll(dumpId);
  } catch (e) {
    return Promise.reject(e);
  }
}
