import { RxDocument } from 'rxdb';
import { FeedbackDocumentType } from './feedback.schema';
import { FeedbackInterface } from 'models/feedback/interfaces/FeedbackInterface';

export type FeedbackDocumentMethods = {
  asFeedback: () => FeedbackInterface;
};
export type FeedbackDocument = RxDocument<FeedbackDocumentType, FeedbackDocumentMethods>;

export const defaultFeedbackDocumentMethods: FeedbackDocumentMethods = {
  asFeedback: function (this: FeedbackDocument) {
    return {
      id: this.id,
      user_id: this.user_id,
      description: this.description,
      _deleted: this._deleted,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  },
};
