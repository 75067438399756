import React from 'react';
import { Text, Heading, Hyperlink } from 'components/common/ComponentLibrary';
import { toast } from 'react-toastify';
import { LostPasswordForm } from 'components/application/LostPassword';
import { getUrlHome } from 'utilities/urls.utils';
import { DumpItIcon } from 'components/common/SpecialIcons';
import Footer from 'components/application/Footer';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

const LostPasswordPage = () => {
  useDocumentTitle('Lost password');

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <div className='flex items-center text-center justify-center mt-5 mb-10 md:mb-32'>
        <Hyperlink href={getUrlHome()}>
          <DumpItIcon className='w-20 aspect-square' />
        </Hyperlink>
      </div>
      <div className='m-auto max-w-sm px-4 md:px-0 flex-1'>
        <div className='space-y-4'>
          <Heading size='xl' textAlign='center' fontWeight='bold'>
            Forgot your password?
          </Heading>
          <Text textAlign='center' spacing='l'>
            Enter your email and we'll send you a link to reset your password.
          </Text>
          <LostPasswordForm
            onReset={() => {
              toast.success('Check your email to reset your password', { autoClose: 5000 });
            }}
          />
        </div>
      </div>
      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default LostPasswordPage;
