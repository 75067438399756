import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikTextareaField } from 'components/common/Formik';
import { createFeedback } from 'models/feedback/services/rxDb/RxDbFeedbackService';
import { useProfileStore } from 'store/profile.store';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object().shape({
  email: yup.string().email().trim().nullable(),
  description: yup
    .string()
    .trim()
    .min(2, `Description should be at least 2 characters`)
    .required('Enter your feedback'),
});

const FeedbackForm = ({ onDone }: { onDone: () => void }) => {
  const { t } = useTranslation('translations');
  const [profile] = useProfileStore((state) => [state.profile]);

  return (
    <Formik
      onSubmit={async ({ description }) => {
        if (!profile?.id) {
          return;
        }

        await createFeedback(description, profile.id);
        toast.success(t('toaster.feedbackSuccess'));
        onDone();
      }}
      initialValues={{ description: '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-4 w-full'>
            <FormikTextareaField name='description' placeholder={t('feedbackModal.placeholder')} />
            <div className='flex justify-between'>
              <Button size='m' onClick={onDone} theme='ghost'>
                {t('feedbackModal.rejectAction')}
              </Button>
              <Button size='m' type='submit' status={isSubmitting ? 'busy' : ''}>
                {t('feedbackModal.acceptAction')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FeedbackForm;
