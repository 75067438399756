import supabase from 'store/supabase/supabase';
import { STORAGE_BUCKET_ENUM } from '../models/application/enums/ImageEnums';

export const getPublicUrl = (path: string, storageBucket: STORAGE_BUCKET_ENUM): string => {
  const { data } = supabase.storage.from(storageBucket).getPublicUrl(path);

  return data.publicUrl;
};

export async function getSignedUrl(path: string, storageBucket: STORAGE_BUCKET_ENUM) {
  const { data } = await supabase.storage.from(storageBucket).createSignedUrl(path, 3600);

  return data?.signedUrl;
}

export function isFilePathImage(path: string) {
  if (!path) {
    return false;
  }

  return path.includes('.png') || path.includes('.jpg') || path.includes('.jpeg');
}

export function isFilePathVideo(path: string) {
  if (!path) {
    return false;
  }

  return path.includes('.mp4');
}

export function isFilePathAudio(path: string) {
  if (!path) {
    return false;
  }

  return path.includes('.wav') || path.includes('.m4a');
}
