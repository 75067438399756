import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

export const settingSchemaLiteral = {
  title: 'dumpit! setting schema',
  version: 2,
  description: 'Represents a users settings',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      minLength: 37,
      maxLength: 37,
    },
    theme: {
      type: 'string',
      default: 'system',
    },
    show_placeholder: {
      type: 'boolean',
      default: true,
    },
    created_at: {
      type: 'string',
      final: true,
    },
    updated_at: {
      type: 'string',
    },
    playspeed: {
      type: 'number',
    },
    density: {
      type: 'string',
    },
  },
  required: ['id', 'theme', 'show_placeholder', 'created_at'],
} as const;

const schemaTyped = toTypedRxJsonSchema(settingSchemaLiteral);
export type SettingDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const settingSchema: RxJsonSchema<SettingDocumentType> = settingSchemaLiteral;
