import { getFilePath } from 'models/dumps/services/rxDb/RxDbAttachmentService';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import { getFileAsBase64, uploadImage, uploadImageFile } from 'utilities/images.utils';
import { getFileSizeMB, resizeFile } from 'utilities/common/file.utils';
import { IMAGE_PREVIEW_POSTFIX } from 'constants/image.constants';
import { getVideoDuration, getVideoThumbnail } from '../video.utils';
import { v4 as uuidv4 } from 'uuid';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { dumpitDatabase } from 'store/rxdb/dumpit.database';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { IDraftAttachment } from 'store/dumpDraft.store';
import { isFilePathVideo } from '../storage.utils';
import { toast } from 'react-toastify';
import { MAX_ATTACHMENTS_REACHED_MESSAGE, MAX_FILE_SIZE } from 'constants/attachment.constants';
import { getAudioDuration } from '../audio.utils';
import { captureMessage } from '@sentry/react';

export const createDumpImageAttachment = async (dumpId: string, file: File, userId: string) => {
  const resizedImageFile = await resizeFile(file);
  if (!resizedImageFile) {
    return;
  }

  const id = uuidv4().toString();
  const filePath = getFilePath(userId, dumpId, id, file.name);
  const newAttachment: AttachmentInterface = {
    id,
    filename: file.name,
    duration: 0,
    playtime: 0,
    file_path: filePath,
    dump_id: dumpId,
    user_id: userId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
  };

  const result = await uploadImageFile(file, STORAGE_BUCKET_ENUM.ATTACHMENTS, filePath);
  if (!result) {
    return;
  }

  await uploadImageFile(
    resizedImageFile,
    STORAGE_BUCKET_ENUM.ATTACHMENTS,
    getFilePath(userId, dumpId, id, resizedImageFile.name, IMAGE_PREVIEW_POSTFIX),
  );

  await dumpitDatabase.attachments.insert(newAttachment);
};

export const createDumpVideoAttachment = async (dumpId: string, file: File, userId: string) => {
  const duration = await getVideoDuration(file);

  const id = uuidv4().toString();
  const filePath = getFilePath(userId, dumpId, id, file.name);
  const newAttachment: AttachmentInterface = {
    id,
    filename: file.name,
    duration,
    playtime: 0,
    file_path: filePath,
    dump_id: dumpId,
    user_id: userId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
  };

  const result = await uploadImageFile(file, STORAGE_BUCKET_ENUM.ATTACHMENTS, filePath);
  if (!result) {
    return;
  }

  const thumbnail = await getVideoThumbnail(file);
  if (thumbnail) {
    await uploadImage(
      thumbnail,
      STORAGE_BUCKET_ENUM.ATTACHMENTS,
      getFilePath(userId, dumpId, id, 'temp.jpeg'),
      'image/jpeg',
    );
  }

  await dumpitDatabase.attachments.insert(newAttachment);
};

export const createDumpAudioAttachment = async (dumpId: string, file: File, userId: string) => {
  const duration = await getAudioDuration(file);

  const id = uuidv4().toString();
  const filePath = getFilePath(userId, dumpId, id, file.name);
  const newAttachment: AttachmentInterface = {
    id,
    filename: file.name,
    duration,
    playtime: 0,
    file_path: filePath,
    dump_id: dumpId,
    user_id: userId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
  };

  const result = await uploadImageFile(file, STORAGE_BUCKET_ENUM.ATTACHMENTS, filePath);
  if (!result) {
    return;
  }

  await dumpitDatabase.attachments.insert(newAttachment);
};

export const getDraftAttachmentsFromFiles = async (files: File[]) => {
  const newAttachments: IDraftAttachment[] = [];
  await Promise.all(
    files.map(async (file) => {
      if (isFilePathVideo(file.name)) {
        const thumbnail = await getVideoThumbnail(file);
        newAttachments.push({
          src: thumbnail,
          file: file,
        });
      } else {
        const src = await getFileAsBase64(file);
        newAttachments.push({
          src,
          file: file,
        });
      }
    }),
  );

  return newAttachments;
};

export const handleFilesRejected = (data: any) => {
  if (data.errors?.[0]?.name === 'FileAmountLimitError') {
    toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
    captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
  }
  if (data.errors?.[0]?.name === 'FileSizeError') {
    toast.error(`You can only upload attachments up to ${getFileSizeMB(MAX_FILE_SIZE)}Mb in size`);
    captureMessage(`You can only upload attachments up to ${getFileSizeMB(MAX_FILE_SIZE)}Mb in size`);
  }
  if (data.errors?.[0]?.name === 'FileTypeError') {
    toast.error(`File type is not allowed`);
    captureMessage(`File type is not allowed`);
  }
};
