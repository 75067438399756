import React, { useEffect } from 'react';
import { DumpItIcon } from 'components/common/SpecialIcons';
import Footer from 'components/application/Footer';
import Authentication from 'components/application/Authentication';
import { useSearchParams } from 'react-router-dom';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import { SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED } from 'constants/supabase.constants';
import { SIGNUPS_INVITE_ONLY } from 'constants/application.constants';

const LandingPageScreen = () => {
  const [searchParams] = useSearchParams();
  const errorDescription = searchParams.get('error_description');

  useEffect(() => {
    if (errorDescription) {
      notifyAboutError(
        new Error(errorDescription),
        true,
        errorDescription === SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED ? SIGNUPS_INVITE_ONLY : errorDescription,
      );
    }
  }, [errorDescription]);

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <main className='m-auto flex flex-col md:flex-row w-full max-w-6xl flex-1 px-5 md:px-0 pb-5 gap-20'>
        <section className='flex items-center md:justify-start justify-center w-full md:w-2/3 md:-mt-10'>
          <div className='flex flex-col justify-center'>
            <div className='flex justify-center'>
              <DumpItIcon className='w-52 md:w-72 aspect-square' />
            </div>
            <h1 className='flex flex-col md:flex-row justify-center items-center md:space-x-5 md:mb-10'>
              <span className='font-sans text-left text-5xl leading-tight tracking-tighter font-normal text-dark dark:text-white line-clamp-none'>
                Think it.
              </span>
              <span className='font-sans text-left text-5xl leading-tight tracking-tighter font-bold text-cyan line-clamp-none'>
                Dump it.
              </span>
              <span className='font-sans text-left text-5xl leading-tight tracking-tighter font-normal text-dark dark:text-white line-clamp-none'>
                Got it.
              </span>
            </h1>
          </div>
        </section>
        <section className='flex items-center flex-col justify-center md:min-w-[400px]'>
          <Authentication />
        </section>
      </main>
      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default LandingPageScreen;
