import { DumpItIcon } from 'components/common/SpecialIcons';
import { Variants, motion } from 'framer-motion';
import React from 'react';

const dumpVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: (isFirstDump: boolean) => ({
    transition: isFirstDump
      ? {
          type: 'spring',
          damping: 50,
          stiffness: 300,
          mass: 3,
        }
      : {
          type: 'tween',
          ease: 'easeOut',
          duration: 0.2,
        },
    scale: isFirstDump ? 0.3 : 1,
    opacity: isFirstDump ? 1 : 0,
    x: isFirstDump ? '23.7vw' : 0,
    y: isFirstDump ? 'calc(-45vh)' : -20,
  }),
};

type Props = { isFirstDump: boolean };

const DumpCreatedNotification: React.FC<Props> = (props) => {
  const { isFirstDump } = props;
  return (
    <motion.div
      variants={dumpVariants}
      initial='hidden'
      animate='visible'
      exit='exit'
      custom={isFirstDump}
      className='flex absolute w-32 h-32 m-auto top-0 bottom-0 right-0 left-0 items-center justify-center z-10'
      transition={{ type: 'tween', ease: 'easeInOut', duration: 0.2 }}
    >
      <DumpItIcon className='w-24 aspect-square' />
    </motion.div>
  );
};

export default DumpCreatedNotification;
