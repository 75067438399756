import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUrlAccount, getUrlDumps, getUrlSettings } from 'utilities/urls.utils';
import { Avatar, Button, ContentLayout, Text, Toggle, Tooltip } from 'components/common/ComponentLibrary';
import { ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { SettingRow } from 'components/settings/SettingRow';
import { useSettingsStore } from 'store/setting.store';
import { useProfileStore } from 'store/profile.store';
import ThemeSelector from 'components/settings/ThemeSelector/ThemeSelector';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import { ToggleEnableLocation } from 'components/settings/ToggleEnableLocation';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import { PLACEHOLDER_TEXT } from 'constants/dumps.constants';
import Footer from 'components/application/Footer';
import { AudioPlayspeedSelector } from 'components/settings/AudioPlayspeedSelector';
import FeedbackModal from 'components/feedback/FeedbackModal';
import { useTranslation } from 'react-i18next';
import { DensitySelector } from 'components/settings/DensitySelector';

const SettingsScreen = () => {
  const { t } = useTranslation('translations');
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);

  useDocumentTitle('Settings');

  const navigate = useNavigate();

  useHotkeys(
    'left',
    () => {
      navigate(getUrlDumps(), { state: { previousRoute: getUrlSettings() } });
    },
    [],
  );
  useHotkeys('right', () => navigate(getUrlAccount(), { state: { previousRoute: getUrlSettings() } }), []);

  const [settings, updateSettings] = useSettingsStore((state) => [state.settings, state.updateSettings]);
  const [profile] = useProfileStore((state) => [state.profile]);

  const displayName = profile?.asProfile().first_name
    ? `${profile?.asProfile().first_name} ${profile?.asProfile().last_name}`.trim()
    : undefined;

  const location = useLocation();
  const isBackwards = location.state === null || location.state.previousRoute === getUrlAccount();

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <ContentLayout>
        <ContentLayout.Top>
          <Button size='l' theme='bare' width='none' href={getUrlDumps()}>
            <Button.Icon icon={<ChevronLeftIcon className='w-6' />} />
          </Button>
        </ContentLayout.Top>
        <ContentLayout.Content>
          <div className='pb-7'>
            <div className='pl-6'>
              <Text size='s' transform='uppercase' color='gray-500' spacing='xs'>
                {t('screens.settings.account.title')}
              </Text>
            </div>
            <ul className='flex flex-col'>
              <SettingRow onClick={() => navigate(getUrlAccount())}>
                <SettingRow.Content>
                  <div className='flex items-center flex-row space-x-4'>
                    <div className='border-2 border-bright dark:border-white rounded-full'>
                      <Avatar src={profile?.asProfile().image_path || undefined} alt='Profile image' size='m' />
                    </div>
                    <div>
                      <Text fontWeight='bold' size='l'>
                        {displayName ?? 'Your Profile'}
                      </Text>
                      <Text size='s'>{t('screens.settings.account.description')}</Text>
                    </div>
                  </div>
                </SettingRow.Content>
                <SettingRow.End>
                  <div className='flex gap-x-2 items-center'>
                    {!profile?.asProfile().image_path && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<ExclamationCircleIcon className='w-6 text-red' />}
                        scope='local'
                      >
                        <Text>{t('screens.settings.account.missingImageTooltip')}</Text>
                      </Tooltip>
                    )}
                    <ChevronRightIcon className='w-6 -mr-2' />
                  </div>
                </SettingRow.End>
              </SettingRow>
            </ul>
          </div>

          <div className='pb-7'>
            <div className='pl-6'>
              <Text size='s' transform='uppercase' color='gray-500' spacing='xs'>
                {t('screens.settings.permissions.title')}
              </Text>
            </div>
            <ul className='flex flex-col'>
              <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.permissions.locationAccessTitle')}</SettingRow.Label>
                <SettingRow.Content>{t('screens.settings.permissions.locationAccessDescription')}</SettingRow.Content>
                <SettingRow.End>
                  <ToggleEnableLocation />
                </SettingRow.End>
              </SettingRow>
            </ul>
          </div>

          <div className='pb-7'>
            <div className='pl-6'>
              <Text size='s' transform='uppercase' color='gray-500' spacing='xs'>
                {t('screens.settings.customization.title')}
              </Text>
            </div>
            <ul className='flex flex-col'>
              <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.customization.placeholderTitle')}</SettingRow.Label>
                <SettingRow.Content>
                  {t('screens.settings.customization.placeholderDescription', { placeholderText: t('placeholder') })}
                </SettingRow.Content>
                <SettingRow.End>
                  <Toggle
                    size='l'
                    name='showPlaceholder'
                    value={settings?.asSettings()?.show_placeholder === true}
                    onChange={async (event) => await updateSettings({ show_placeholder: event.target.checked })}
                  />
                </SettingRow.End>
              </SettingRow>

              <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.customization.densityTitle')}</SettingRow.Label>
                <SettingRow.Content>{t('screens.settings.customization.descriptionDensity')}</SettingRow.Content>
                <SettingRow.End>
                  <DensitySelector />
                </SettingRow.End>
              </SettingRow>

              <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.customization.themeTitle')}</SettingRow.Label>
                <SettingRow.End>
                  <ThemeSelector />
                </SettingRow.End>
              </SettingRow>

              <SettingRow status='disabled'>
                <SettingRow.Label>{t('screens.settings.customization.audioTitle')}</SettingRow.Label>
                <SettingRow.End>
                  <AudioPlayspeedSelector />
                </SettingRow.End>
              </SettingRow>
            </ul>
          </div>

          <ul className='flex flex-col pb-4'>
            <SettingRow onClick={() => setShowFeedbackModal(true)}>
              <SettingRow.Label>{t('screens.settings.other.feedbackTitle')}</SettingRow.Label>
              <SettingRow.Content>{t('screens.settings.other.feedbackDescription')}</SettingRow.Content>
            </SettingRow>
            {showFeedbackModal && <FeedbackModal onDismiss={() => setShowFeedbackModal(false)} />}
          </ul>
        </ContentLayout.Content>
        <ContentLayout.Footer>
          <Footer />
        </ContentLayout.Footer>
      </ContentLayout>
    </motion.div>
  );
};

export default SettingsScreen;
