import { RxDatabase, addRxPlugin, createRxDatabase } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { AttachmentCollection, defaultAttachmentCollectionMethods } from './attachment/attachment.collection';
import { attachmentSchema } from './attachment/attachment.schema';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { defaultAttachmentDocumentMethods } from './attachment/attachment.document';
import { DumpCollection, defaultDumpCollectionsMethods } from './dump/dump.collection';
import { defaultDumpDocumentMethods } from './dump/dump.document';
import { dumpSchema } from './dump/dump.schema';
import { DATABASE_NAME_INDEX_DB } from 'constants/application.constants';
import { settingSchema } from './settings/setting.schema';
import { profileSchema } from './profile/profile.schema';
import supabase from '../supabase/supabase';
import { SettingCollection } from './settings/setting.collection';
import { defaultSettingsDocumentMethods } from './settings/setting.document';
import { ProfileCollection } from './profile/profile.collection';
import { defaultProfileDocumentMethods } from './profile/profile.document';
import { getEnvironmentVariable } from 'utilities/env.utils';
import { SupabaseReplication } from 'utilities/supabase-replication';
import { tagSchema } from './tag/tag.schema';
import { defaultTagDocumentMethods } from './tag/tag.document';
import { defaultTagCollectionMethods, TagCollection } from './tag/tag.collection';
import { dumpChangesListener } from './dumpChangesListener';
import { FeedbackCollection } from './feedback/feedback.collection';
import { defaultFeedbackDocumentMethods } from './feedback/feedback.document';
import { DENSITY } from '../../constants/density.constants';

addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBAttachmentsPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

export type DumpitCollections = {
  dumps: DumpCollection;
  settings: SettingCollection;
  profiles: ProfileCollection;
  attachments: AttachmentCollection;
  tags: TagCollection;
  feedback: FeedbackCollection;
};
export type IDumpitDatabase = RxDatabase<DumpitCollections>;

export const dumpitDatabase: IDumpitDatabase = await (async () => {
  // try {
  //   await removeRxDatabase(DATABASE_NAME_INDEX_DB, getRxStorageDexie());
  // } catch {
  //   // ignore
  // }

  const {
    data: { session },
  } = await supabase.auth.getSession();

  const db = await createRxDatabase<IDumpitDatabase>({
    name: DATABASE_NAME_INDEX_DB,
    storage: getRxStorageDexie(),
    multiInstance: false,
  });

  const collections = await db.addCollections({
    profiles: {
      schema: profileSchema,
      methods: defaultProfileDocumentMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
      },
    },
    settings: {
      schema: settingSchema,
      methods: defaultSettingsDocumentMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
        2: (oldDoc) => {
          return { ...oldDoc, density: DENSITY.DEFAULT };
        },
      },
    },
    dumps: {
      schema: dumpSchema,
      methods: defaultDumpDocumentMethods,
      statics: defaultDumpCollectionsMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
      },
    },
    tags: {
      schema: tagSchema,
      methods: defaultTagDocumentMethods,
      statics: defaultTagCollectionMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
      },
    },
    feedback: {
      schema: tagSchema,
      methods: defaultFeedbackDocumentMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
      },
    },
    attachments: {
      schema: attachmentSchema,
      methods: defaultAttachmentDocumentMethods,
      statics: defaultAttachmentCollectionMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
      },
    },
  });

  collections.dumps.$.subscribe((change: any) => {
    dumpChangesListener(change);
  });

  if (session) {
    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.settings,
      replicationIdentifier: `settings${getEnvironmentVariable('SUPABASE_PROJECT_URL')}`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });

    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.profiles,
      replicationIdentifier: `profiles${getEnvironmentVariable('SUPABASE_PROJECT_URL')}`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });

    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.attachments,
      replicationIdentifier: `attachments${getEnvironmentVariable('SUPABASE_PROJECT_URL')}`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });

    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.dumps,
      replicationIdentifier: `dumps${getEnvironmentVariable('SUPABASE_PROJECT_URL')}`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });

    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.feedback,
      replicationIdentifier: `feedback${getEnvironmentVariable('SUPABASE_PROJECT_URL')}`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });
  }

  return db;
})();
