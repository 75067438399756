import React, { ReactNode, useState } from 'react';
import ConfirmationDialog from 'components/common/dialog/ConfirmationDialog.component';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import useDeleteDump from 'hooks/dumps/useDeleteDump';

interface IDumpDeleteAction {
  dump: DumpInterface;
  children: (props: { deleteDump: () => void }) => ReactNode;
  showConfirmation?: boolean;
}

const DumpDeleteAction = ({ children, dump, showConfirmation = true }: IDumpDeleteAction) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { deleteDump } = useDeleteDump(dump);

  return (
    <>
      {showConfirmationModal && (
        <BackdropComponent dismiss={() => setShowConfirmationModal(false)}>
          <ConfirmationDialog
            title='Delete Dump?'
            leftItem={{ title: 'No', action: () => setShowConfirmationModal(false) }}
            rightItem={{
              title: 'Yes',
              action: deleteDump,
              className: 'hover:bg-red/10 text-red',
            }}
          />
        </BackdropComponent>
      )}
      {children?.({
        deleteDump: showConfirmation ? () => setShowConfirmationModal(true) : deleteDump,
      })}
    </>
  );
};

export default DumpDeleteAction;
