import React from 'react';
import { Text } from 'components/common/ComponentLibrary';
import BackdropComponent from 'components/common/dialog/Backdrop.component';

const AttachmentVideoModal = ({ onDismiss, src }: { onDismiss: () => void; src: string }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='w-full'>
      <video
        autoPlay={true}
        controls={true}
        className='w-full max-w-5xl relative rounded-2xl'
        controlsList='noplaybackrate'
      >
        <source src={src} type='video/mp4' />
        <Text color='red'>Your browser does not support the video tag.</Text>
      </video>
    </div>
  </BackdropComponent>
);

export default AttachmentVideoModal;
