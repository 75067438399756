import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import ProfilePhoneForm from './ProfilePhoneForm';
import { Heading } from 'components/common/ComponentLibrary';
import { useTranslation } from 'react-i18next';

const ProfilePhoneModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const { t } = useTranslation('translations');

  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full p-6'>
        <Heading size='l' textAlign='left' spacing='m'>
          {t('phoneModal.title')}
        </Heading>
        <ProfilePhoneForm onChanged={onDismiss} />
      </div>
    </BackdropComponent>
  );
};

export default ProfilePhoneModal;
