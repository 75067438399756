import { useEffect, useState } from 'react';
import { useSettingsStore } from 'store/setting.store';
import supabase from 'store/supabase/supabase';
import useRxDb from './useRxDb';
import { useProfileStore } from 'store/profile.store';
import { useAuthStore } from 'store/auth.store';
import { mixpanelIdentify } from 'models/application/services/TrackingService';
import { toast } from 'react-toastify';
import { captureMessage } from '@sentry/react';

const POLL_INTERVAL = 1000; // 1 second.
const TIMEOUT = 5000;

const useInitSession = () => {
  const [isInitialising, setIsInitialising] = useState<boolean>(true);

  const [getSettings, setSettings] = useSettingsStore((state) => [state.getSettings, state.setSettings]);
  const [getProfile, setProfile] = useProfileStore((state) => [state.getProfile, state.setProfile]);
  const [setCurrentUser, logout, currentUser] = useAuthStore((state) => [
    state.setCurrentUser,
    state.logout,
    state.currentUser,
  ]);

  useEffect(() => {
    (async () => {
      const {
        data: { session: authSession },
      } = await supabase.auth.getSession();
      if (!authSession) {
        setIsInitialising(false);
        return;
      }

      setCurrentUser(authSession.user, authSession);
    })();
  }, []);

  const { db } = useRxDb();

  useEffect(() => {
    if (db && currentUser) {
      let hasDataLoaded = false;
      const timeoutId = setTimeout(async () => {
        if (!hasDataLoaded) {
          captureMessage(`Failed to load data for: ${currentUser?.id}. Logging user out.`);
          toast.info('Could not sign you in right now. Please try again.');
          await logout();
          setIsInitialising(false);
        }
      }, TIMEOUT);

      (async () => {
        const pollData = async () => {
          const dbSettings = await getSettings();
          const dbProfile = await getProfile();
          if (dbSettings && dbProfile) {
            clearTimeout(timeoutId);
            hasDataLoaded = true;
            setSettings(dbSettings);
            setProfile(dbProfile);
            setIsInitialising(false);
            mixpanelIdentify(dbProfile.asProfile());
          } else {
            setTimeout(pollData, POLL_INTERVAL);
          }
        };

        await pollData();
      })();

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [db, currentUser]);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      switch (event) {
        case 'INITIAL_SESSION':
        case 'SIGNED_IN':
          if (!session) {
            logout();
          }
          break;
        default:
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { isInitialising };
};

export default useInitSession;
