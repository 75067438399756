import React, { useState } from 'react';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { PlayCircleIcon } from '@heroicons/react/24/outline';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import AttachmentVideoModal from './AttachmentVideoModal';
import LoadingPlaceholder from './LoadingPlaceholder';
import { formatSecondsToHoursMinutes } from 'utilities/video.utils';
import { Text } from 'components/common/ComponentLibrary';

const VideoPreview = ({
  src,
  previewSrc,
  onRemove,
  duration,
}: {
  src: string;
  previewSrc?: string | null;
  onRemove?: () => void;
  duration?: number;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className='relative inline-block group'>
      {onRemove && (
        <MinusCircleIcon
          className='w-6 text-red/60 cursor-pointer hidden group-hover:block absolute z-[500] -right-2 -top-2 hover:text-red rounded-2xl'
          onClick={onRemove}
        />
      )}
      <div onClick={() => setIsModalOpen(true)} className='cursor-pointer w-18 h-18'>
        <MotionEffectPopIn>
          <>
            {!previewSrc && <LoadingPlaceholder />}
            {previewSrc && (
              <div className='relative'>
                <div className='absolute bg-cyan/20 hover:bg-cyan/30 rounded-xl w-full h-full'>
                  <PlayCircleIcon className='w-8 h-8 text-cyan absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
                </div>
                {duration && duration > 0 ? (
                  <div className='absolute bottom-1 right-1'>
                    <Text color='cyan' size='s' tag='div'>
                      <span className='text-[11px]'>{formatSecondsToHoursMinutes(duration)}</span>
                    </Text>
                  </div>
                ) : null}
                <img
                  src={previewSrc}
                  alt='Preview'
                  width='100'
                  className='w-18 h-18 object-cover rounded-xl border-1 border-dark/50'
                />
              </div>
            )}
          </>
        </MotionEffectPopIn>
      </div>
      {isModalOpen && <AttachmentVideoModal src={src} onDismiss={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default VideoPreview;
