import { DumpInterface } from '../interfaces/DumpInterface';
import { LocationInterface } from '../interfaces/LocationInterface';
import { IDump } from 'models/dump.model';

export class Dump implements DumpInterface {
  readonly id: string;
  text?: string;
  tags?: string[];
  location?: LocationInterface | null;
  updated_at: string;
  created_at: string;
  user_id?: string;
  reminder?: string;
  _deleted?: boolean;
  is_pinned?: boolean;

  constructor(dump: IDump) {
    this.id = dump.id;
    this.text = dump.text;
    this.tags = dump.tags;
    this.location = dump.location;
    this.updated_at = dump.updated_at;
    this.created_at = dump.created_at;
    this.user_id = dump.user_id;
    this.reminder = dump.reminder;
    this._deleted = dump._deleted;
    this.is_pinned = dump.is_pinned;
  }
}
