import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import supabase from 'store/supabase/supabase';
import { decode } from 'base64-arraybuffer';
import { ImageType } from 'react-images-uploading';
import { IMAGE_PREVIEW_POSTFIX } from 'constants/image.constants';

export interface IPixelCrop {
  width: number;
  height: number;
  x: number;
  y: number;
}

const createImage = (imageSrc: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = imageSrc;
  });

export async function getCroppedImg(imageSrc: string, pixelCrop: IPixelCrop): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx!.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx!.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx!.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  return canvas.toDataURL('image/jpeg');
}

export const cropImage = async (image: ImageType, croppedAreaPixels?: IPixelCrop, onError?: (err: Error) => void) => {
  try {
    if (!croppedAreaPixels || !image.dataURL) {
      return;
    }

    return await getCroppedImg(image.dataURL, croppedAreaPixels);
  } catch (err) {
    onError && onError(err as Error);
  }
};

export const uploadImage = async (
  base64String: string,
  storageBucket: STORAGE_BUCKET_ENUM,
  path: string,
  contentType: string,
): Promise<{ path: string; imageUrl: string } | null> => {
  const base64 = base64String.split('base64,')[1];
  const { data, error } = await supabase.storage.from(storageBucket).upload(path, decode(base64), {
    contentType: contentType,
    cacheControl: '3600',
    upsert: true,
  });
  if (error) {
    return null;
  }

  const { data: imageFromStorage } = supabase.storage.from(storageBucket).getPublicUrl(data.path);

  return {
    path: data.path,
    imageUrl: imageFromStorage.publicUrl,
  };
};

// @todo-phil move this somewhere more general
export const uploadImageFile = async (
  file: File,
  storageBucket: STORAGE_BUCKET_ENUM,
  path: string,
): Promise<{ path: string; imageUrl: string } | null> => {
  const { data, error } = await supabase.storage.from(storageBucket).upload(path, file, {
    contentType: file.type,
    cacheControl: '3600',
    upsert: true,
  });
  if (error) {
    return null;
  }

  const { data: imageFromStorage } = supabase.storage.from(storageBucket).getPublicUrl(data.path);

  return {
    path: data.path,
    imageUrl: imageFromStorage.publicUrl,
  };
};

export const getFileAsBase64 = (file: File): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        resolve(result);
      } else {
        resolve(null);
      }
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsDataURL(file);
  });
};

export const getPreviewFilePath = (filePath: string) => {
  const lastDotIndex = filePath.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return filePath;
  }

  return filePath.substring(0, lastDotIndex) + IMAGE_PREVIEW_POSTFIX + filePath.substring(lastDotIndex);
};
