export const DATABASE_NAME_INDEX_DB = 'dumpit';

export const NODE_ENVIRONMENT_LOCALHOST = 'LOCALHOST';
export const NODE_ENVIRONMENT_DEVELOPMENT = 'DEV';
export const NODE_ENVIRONMENT_PRODUCTION = 'PRODUCTION';
export const NODE_ENVIRONMENT_STAGING = 'STAGING';

export const SUPABASE_EDGE_FUNCTION_DELETE_USER = 'user-self-deletion';

export const SIGNUPS_INVITE_ONLY = 'Sign-ups are currently invite-only';
