import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import { getUrlGoogleMaps } from 'utilities/urls.utils';

import 'leaflet/dist/leaflet.css';

const MapTile = ({ location, onRemove }: { location: [number, number]; onRemove: () => void }) => (
  <div className='relative inline-block group'>
    <MinusCircleIcon
      className='w-6 text-red/60 cursor-pointer hidden group-hover:block absolute z-[500] -right-2 -top-2 hover:text-red rounded-2xl'
      onClick={onRemove}
    />
    <a href={getUrlGoogleMaps(location[0], location[1])} target='_blank' rel='noreferrer'>
      <MotionEffectPopIn>
        <div className='relative'>
          <div className='absolute hover:bg-cyan/30 rounded-xl w-full h-full z-[499]' />
          <MapContainer
            attributionControl={false}
            dragging={false}
            zoomControl={false}
            center={location}
            zoom={8}
            scrollWheelZoom={false}
            className='w-18 h-18 rounded-xl'
          >
            <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
          </MapContainer>
        </div>
      </MotionEffectPopIn>
    </a>
  </div>
);

export default MapTile;
