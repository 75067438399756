import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { LocationInterface } from 'models/dumps/interfaces/LocationInterface';

export type IDraftAttachment = {
  src: string | null;
  file: File;
};

export type IDumpDraftStore = {
  text: string;
  location?: LocationInterface | null;
  audio: File[];
  attachments: IDraftAttachment[];

  setText: (text: string) => void;
  setLocation: (location?: LocationInterface | null) => void;
  setAudio: (audio: File[]) => void;
  setAttachments: (attachments: IDraftAttachment[]) => void;
  resetStore: () => void;
  isEmpty: () => boolean;
};

export const useDumpDraftStore = create<IDumpDraftStore>((set, getState) => ({
  text: '',
  location: null,
  audio: [],
  attachments: [],

  setText: (text) =>
    set(() => ({
      text,
    })),

  setLocation: (location) =>
    set(() => ({
      location: location,
    })),

  setAudio: (audio) =>
    set(() => ({
      audio,
    })),

  setAttachments: (attachments) =>
    set(() => ({
      attachments,
    })),

  resetStore: () =>
    set(() => ({
      text: '',
      audio: [],
      attachments: [],
      location: null,
    })),

  isEmpty: () =>
    getState().text?.trim().length === 0 && getState().attachments.length === 0 && getState().audio.length === 0,
}));

mountStoreDevtool('DumpDraftStore', useDumpDraftStore);
