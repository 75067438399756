import React from 'react';

import { format } from 'date-fns';
import { Text, Hyperlink } from 'components/common/ComponentLibrary';
import { getUrlImpressum } from 'utilities/urls.utils';

const Footer = () => (
  <div className='flex flex-col items-center justify-between py-5 md:flex-row'>
    <div>
      <Text color='gray-500' size='s'>{`© ${format(new Date(), 'yyyy')} Dump it!`}</Text>
    </div>
    <div className='flex flex-row space-x-5'>
      <Text size='s' tag='div' color='gray-500'>
        <Hyperlink href={getUrlImpressum()}>Impressum</Hyperlink>
      </Text>
      {/*<Text size='s' tag='div' color='gray-500'>*/}
      {/*  <Hyperlink href={getUrlPrivacy()}>Privacy Policy</Hyperlink>*/}
      {/*</Text>*/}
      {/*<Text size='s' tag='div' color='gray-500'>*/}
      {/*  <Hyperlink href={getUrlCookies()}>Cookie Policy</Hyperlink>*/}
      {/*</Text>*/}
    </div>
  </div>
);

export default Footer;
