import React from 'react';
import { MotionEffectPopIn } from 'components/common/MotionEffects';
import LoadingPlaceholder from './LoadingPlaceholder';

const DumpRowImagePreview = ({ previewSrc }: { previewSrc?: string | null }) => {
  if (!previewSrc) {
    return <LoadingPlaceholder />;
  }

  return (
    <div className='relative inline-block'>
      <div className='cursor-pointer w-12 h-12'>
        <MotionEffectPopIn>
          <div className='relative'>
            <div className='absolute rounded-xl w-full h-full' />
            <img
              src={previewSrc}
              alt='Preview'
              width='100'
              className='w-12 h-12 object-cover rounded-xl border-1 border-dark/50'
            />
          </div>
        </MotionEffectPopIn>
      </div>
    </div>
  );
};

export default DumpRowImagePreview;
