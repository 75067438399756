import { IDump } from 'models/dump.model';
import React, { ComponentPropsWithoutRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from 'themes/colors';
import {
  ChevronRightIcon,
  MapPinIcon,
  PhotoIcon,
  MicrophoneIcon,
  FilmIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { getUrlDump } from 'utilities/urls.utils';
import { Text, Tooltip } from 'components/common/ComponentLibrary';
import useDumpAttachments from 'hooks/dumps/useDumpAttachments';
import pluralize from 'pluralize';
import { useContextMenu } from 'use-context-menu';
import { isFilePathAudio, isFilePathImage, isFilePathVideo } from 'utilities/storage.utils';
import { format, isFuture } from 'date-fns';
import JoinChildren from 'components/common/JoinChildren';
import RowContextMenuComponent from 'components/dump-list/item/RowContextMenu.component';
import DumpRowContents from './DumpRowContents';
import './DumpRow.css';
import 'use-context-menu/styles.css';

type Props = {
  searchTerm: string;
  dump: IDump;
} & ComponentPropsWithoutRef<'li'>;

const DumpRow: React.FC<Props> = (props) => {
  const { dump, searchTerm, style, ...listItemProps } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { attachments } = useDumpAttachments(dump.id);

  const { contextMenu, onContextMenu } = useContextMenu(<RowContextMenuComponent dump={dump} />);

  return (
    <li
      onContextMenu={onContextMenu}
      {...listItemProps}
      style={
        isHovered
          ? {
              ...style,
              backgroundColor: COLORS.cyan.alpha(0.25),
            }
          : style
      }
    >
      {contextMenu}
      <Link
        to={getUrlDump(dump)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
        className={isHovered ? 'active' : ''}
      >
        <div className='row-link-container'>
          <div className='flex flex-col space-y-2'>
            <DumpRowContents dump={dump} attachments={attachments} searchTerm={searchTerm} />
            {(dump.reminder || dump.location || (attachments && attachments?.length > 0)) && dump.text && (
              <>
                <JoinChildren
                  separator={
                    <span key='separator' className='text-gray-500'>
                      &middot;
                    </span>
                  }
                >
                  {dump.location && (
                    <Tooltip size='s' spacing='s' target={<MapPinIcon className='w-4 text-gray-500' />} scope='local'>
                      <Text>{`${dump.location?.latitude}, ${dump.location?.longitude}`}</Text>
                    </Tooltip>
                  )}
                  {attachments &&
                    attachments?.filter((attachment) => isFilePathImage(attachment.file_path)).length > 0 && (
                      <Tooltip size='s' spacing='s' target={<PhotoIcon className='w-4 text-gray-500' />} scope='local'>
                        <Text>{`${pluralize(
                          'Images',
                          attachments?.filter((attachment) => isFilePathImage(attachment.file_path)).length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {attachments &&
                    attachments?.filter((attachment) => isFilePathVideo(attachment.file_path)).length > 0 && (
                      <Tooltip size='s' spacing='s' target={<FilmIcon className='w-4 text-gray-500' />} scope='local'>
                        <Text>{`${pluralize(
                          'Videos',
                          attachments?.filter((attachment) => isFilePathVideo(attachment.file_path)).length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {attachments &&
                    attachments?.filter((attachment) => isFilePathAudio(attachment.file_path)).length > 0 && (
                      <Tooltip
                        size='s'
                        spacing='s'
                        target={<MicrophoneIcon className='w-4 text-gray-500' />}
                        scope='local'
                      >
                        <Text>{`${pluralize(
                          'Audio clip',
                          attachments?.filter((attachment) => isFilePathAudio(attachment.file_path)).length,
                          true,
                        )}`}</Text>
                      </Tooltip>
                    )}
                  {dump.reminder && isFuture(new Date(`${dump.reminder}Z`)) && (
                    <Tooltip size='s' spacing='s' target={<ClockIcon className='w-4 text-gray-500' />} scope='local'>
                      <Text>Reminder on {format(new Date(`${dump.reminder}Z`), 'MMMM do, yyyy, HH:mm')}</Text>
                    </Tooltip>
                  )}
                </JoinChildren>
              </>
            )}
          </div>
          <div className='w-6'>
            <ChevronRightIcon className='w-6 text-gray-500' />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default DumpRow;
