import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { IDump } from 'models/dump.model';
import useRxDb from 'hooks/application/useRxDb';
import { getAllDumps } from 'models/dumps/services/rxDb/RxDbDumpService';
import { sortDumps } from 'utilities/dumps/dumps.utils';

const useDumps = () => {
  let subscription: Subscription | undefined;
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dumps, setDumps] = useState<IDump[]>([]);
  const { db } = useRxDb();

  useEffect(() => {
    setIsLoading(true);
    getAllDumps()
      .then((docs) => docs.map((doc) => doc.asDump()))
      .then(sortDumps)
      .then(setDumps)
      .catch(setError)
      .finally(() => setIsLoading(false));
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!db) return;

    subscription = db.dumps.$.subscribe((event) => {
      const newDump: IDump = {
        id: event.documentId,
        text: event.documentData.text,
        location: event.documentData.location,
        updated_at: event.documentData.updated_at,
        last_edited_at: event.documentData.last_edited_at,
        created_at: event.documentData.created_at,
        reminder: event.documentData.reminder,
        user_id: event.documentData.user_id,
        is_pinned: event.documentData.is_pinned,
      };
      switch (event.operation) {
        case 'INSERT':
        case 'UPDATE':
          setDumps([
            ...(event.operation === 'INSERT' ? dumps : dumps.filter((dump) => dump.id !== event.documentId)),
            newDump,
          ]);
          break;
        case 'DELETE':
          setDumps(dumps.filter((dump) => dump.id !== event.documentId));
          break;
      }
      return () => {
        subscription?.unsubscribe();
      };
    });
  }, [db, dumps]);

  return {
    isLoading,
    error,
    dumps,
  };
};

export default useDumps;
