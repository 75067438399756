import React from 'react';
import { toast } from 'react-toastify';
import { getUrlDumps } from 'utilities/urls.utils';
import { useNavigate } from 'react-router-dom';
import { deleteDump, undoDeleteDump } from 'models/dumps/services/rxDb/RxDbDumpService';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Text } from 'components/common/ComponentLibrary';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { useTranslation } from 'react-i18next';

const ToasterUndo = ({ dump }: { dump: DumpInterface }) => {
  const { t } = useTranslation('translations');

  return (
    <div className='flex w-full justify-between items-center'>
      <div className='flex items-center gap-2'>
        <TrashIcon className='w-5' />
        <Text>{t('toaster.dumpDeleted')}</Text>
      </div>
      <button
        onClick={() => undoDeleteDump(dump)}
        className='flex px-4 py-1 rounded-full hover:bg-cyan bg-cyan/25 text-cyan hover:text-white hover:dark:text-dark font-medium text-sm'
      >
        Undo
      </button>
    </div>
  );
};

const useDeleteDump = (dump: DumpInterface) => {
  const navigate = useNavigate();
  const handleDeleteDump = async () => {
    await deleteDump(dump.id);

    trackEvent(TRACKING_EVENT_ENUM.DUMP_DELETED);

    toast(<ToasterUndo dump={dump} />, { autoClose: 10000 });

    navigate(getUrlDumps());
  };

  return {
    deleteDump: handleDeleteDump,
  };
};

export default useDeleteDump;
