import { formatDistanceToNow } from 'date-fns';

export const formatDistanceToNowInUsersTimezone = (date: string): string => {
  return formatDistanceToNow(new Date(`${date}Z`), { addSuffix: true });
};

export const getCurrentUTCDateFormatted = (): string => {
  const currentDate = new Date();

  // Get the ISO string representation of the date (in UTC)
  const isoString = currentDate.toISOString();

  // Remove the milliseconds and the trailing 'Z' to match the desired format
  return isoString.split('.')[0];
};

export const formatSeconds = (seconds: number): string => {
  return `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, '0')}`;
};
