import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppContainer from './AppContainer';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import translations_en from './translations/en/translations.json';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translations: translations_en,
    },
  },
});

Sentry.init({
  dsn: 'https://7bc3f0c636b07a425ad4180c0a8c04c0@o4506031930081280.ingest.sentry.io/4506031931916288',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <IntlProvider locale={navigator.language}>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
        <ToastContainer
          position='top-center'
          hideProgressBar={true}
          closeButton={false}
          closeOnClick={true}
          draggable={true}
          limit={3}
          autoClose={1000}
          toastClassName='bg-neutral-100 dark:bg-neutral-700 text-dark dark:text-white rounded-xl tracking-wider'
        />
      </I18nextProvider>
    </IntlProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
