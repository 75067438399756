import { useEffect } from 'react';
import { validateFile } from 'utilities/common/file.utils';
import { MAX_FILE_SIZE } from 'constants/attachment.constants';
import { ALLOWED_FILE_EXTENSIONS } from 'constants/file.constants';

interface IUseFilePaste {
  onFileSuccessfullyPasted: (file: File) => any;
  onFileRejected: (file: { name: string } | undefined) => any;
}

export const useFilePaste = ({ onFileSuccessfullyPasted, onFileRejected }: IUseFilePaste): void => {
  useEffect(() => {
    const pasteEventListener = (e: ClipboardEvent) => {
      if (!e.clipboardData) return;
      const item = Array.from(e.clipboardData.items).find((item) => /^image\//.test(item.type));

      if (item) {
        const file = item.getAsFile();
        if (file) {
          const validatedFileError = validateFile(file, {
            maxSize: MAX_FILE_SIZE,
            allowedTypes: ALLOWED_FILE_EXTENSIONS,
          });
          if (validatedFileError) {
            onFileRejected(validatedFileError);
            return;
          }

          onFileSuccessfullyPasted(file);
        }
      }
    };

    window.addEventListener('paste', pasteEventListener);

    return () => {
      window.removeEventListener('paste', pasteEventListener);
    };
  }, []);
};
