import classNames from 'classnames';
import React, { ForwardedRef } from 'react';
import Spinner from 'components/common/Spinner';

const mapThemeColor = {
  solid: {
    default: `focus-within:bg-cyan/60 text-white bg-cyan disabled:bg-zinc-200 dark:disabled:bg-zinc-800 disabled:text-gray-300 dark:disabled:text-gray-600 hover:bg-cyan/60`,
    red: `focus-within:bg-red/60 text-white bg-red disabled:bg-zinc-200 dark:disabled:bg-zinc-800 disabled:text-gray-300 dark:disabled:text-gray-600 focus:shadow-outline-danger hover:bg-red/60`,
    white:
      'focus-within:bg-gray-100 bg-white dark:text-paperdark disabled:bg-zinc-200 dark:disabled:bg-zinc-800 disabled:text-gray-300 dark:disabled:text-gray-600 focus:bg-gray-200 focus:shadow-outline hover:bg-gray-100 border border-gray-200',
    green: `focus-within:bg-green/60 text-white bg-green disabled:bg-zinc-200 dark:disabled:bg-zinc-800 disabled:text-gray-300 dark:disabled:text-gray-600 hover:bg-green/60`,
  },
  ghost: {
    default: `focus-within:bg-cyan focus-within:text-white bg-transparent border border-cyan text-cyan hover:bg-cyan hover:text-white`,
    red: `focus-within:bg-red focus-within:text-white text-red border border-red hover:bg-red hover:text-white focus:shadow-outline-danger`,
    white:
      'focus-within:text-gray-900 focus-within:bg-white text-white border border-white hover:text-gray-900 hover:bg-white focus:shadow-outline',
    green: `text-green border border-green hover:bg-green hover:text-white`,
  },
  bare: {
    default: 'focus-within:text-cyan/60 text-cyan hover:text-cyan/60',
    red: 'focus-within:text-red/60 text-red hover:text-red/60',
    white: 'text-white focus-within:text-white/60 hover:text-white/60',
    green: 'focus-within:text-green/60 text-green hover:text-green/60',
  },
};

const mapBaseSize = {
  s: {
    full: 'h-8 px-4 text-sm w-full rounded-xl',
    auto: 'h-8 px-4 text-sm rounded-xl',
    square: 'h-8 w-8 text-sm shrink-0 rounded-full',
    none: 'h-8 text-sm',
  },
  m: {
    full: 'h-12 px-4 w-full rounded-2xl text-xl',
    auto: 'h-12 px-6 rounded-2xl text-xl',
    square: 'h-12 w-12 shrink-0 rounded-full text-xl',
    none: 'h-12 text-xl',
  },
  l: {
    full: 'h-14 px-5 text-2xl w-full rounded-2xl',
    auto: 'h-14 px-5 text-2xl rounded-2xl',
    square: 'h-14 w-14 text-2xl shrink-0 rounded-full',
    none: 'h-14 text-2xl ',
  },
};

const mapAlign = {
  center: 'justify-center',
  left: 'justify-start',
  right: 'justify-end',
};

const getButtonChildren = (children: React.ReactNode) => {
  return React.Children.toArray(children).map((child: React.ReactNode) => {
    if (React.isValidElement(child) && (child?.type as unknown as { role: string })?.role === 'Button.Icon') {
      return <div key='icon'>{child}</div>;
    }

    if (typeof child === 'string' || child instanceof String) {
      return <div key={`label-${child}`}>{child}</div>;
    }

    return null;
  });
};

interface ButtonBase {
  children: React.ReactNode;
  onClick?: () => void;
  target?: string;
  href?: string;
  size?: 's' | 'm' | 'l';
  status?: '' | 'disabled' | 'busy';
  theme?: 'solid' | 'ghost' | 'bare';
  type?: 'button' | 'submit' | 'reset';
  color?: 'default' | 'red' | 'white' | 'green';
  width?: 'auto' | 'full' | 'square' | 'none';
  align?: 'center' | 'left' | 'right';
  forwardRef: ForwardedRef<HTMLButtonElement>;
}

const ButtonBase = ({
  theme = 'solid',
  size = 'm',
  color = 'default',
  status = '',
  width = 'auto',
  align = 'center',
  children,
  onClick,
  type = 'button',
  forwardRef,
}: ButtonBase) => {
  const buttonChildren = getButtonChildren(children);

  const className = classNames(
    'flex relative items-center space-x-2 whitespace-no-wrap disabled:cursor-not-allowed focus:outline-none transition duration-300 font-medium',
    mapThemeColor[theme][color],
    mapBaseSize[size][width],
    mapAlign[align],
  );

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={status === 'disabled' || status === 'busy'}
      className={className}
      ref={forwardRef}
    >
      {status === 'busy' ? (
        <div className='flex items-center space-x-2'>
          <Spinner size='s' />
        </div>
      ) : (
        <div className='flex items-center space-x-3 whitespace-pre'>
          {buttonChildren.filter((buttonChild: React.ReactNode) => buttonChild)}
        </div>
      )}
    </button>
  );
};

export default ButtonBase;
