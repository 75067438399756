import { IDump } from 'models/dump.model';
import React, { ComponentPropsWithoutRef } from 'react';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Menu, Text } from 'components/common/ComponentLibrary';
import { DumpDeleteAction, DumpCopyAction, DumpPinAction } from 'components/dumps/Actions';
import { PinIcon } from 'components/common/SpecialIcons';
import { useTranslation } from 'react-i18next';

export type ContextMenuProps = {
  dump: IDump;
} & ComponentPropsWithoutRef<'div'>;

const RowContextMenuComponent: React.FC<ContextMenuProps> = ({ dump }) => {
  const { t } = useTranslation('translations');

  return (
    <div className='flex fixed z-2 bg-bright shadow-xl text-dark dark:text-white dark:bg-paperdark rounded-xl border-1 border-dark/25 dark:border-white/25 overflow-hidden'>
      <Menu>
        <DumpPinAction dump={dump}>
          {({ pinDump }: { pinDump: () => void }) => (
            <Menu.Item onClick={pinDump} icon={<PinIcon className='h-5' />}>
              <Text color='inherit'>
                {dump.is_pinned ? t('screens.list.contextMenu.unpin') : t('screens.list.contextMenu.pin')}
              </Text>
            </Menu.Item>
          )}
        </DumpPinAction>
        <DumpCopyAction dump={dump}>
          {({ copyDump }: { copyDump: () => void }) => (
            <Menu.Item onClick={copyDump} icon={<DocumentDuplicateIcon className='h-5' />}>
              <Text color='inherit'>{t('screens.list.contextMenu.copy')}</Text>
            </Menu.Item>
          )}
        </DumpCopyAction>
        <DumpDeleteAction dump={dump} showConfirmation={false}>
          {({ deleteDump }: { deleteDump: () => void }) => (
            <Menu.Item onClick={deleteDump} icon={<TrashIcon className='h-5' />}>
              <Text color='inherit'>{t('screens.list.contextMenu.delete')}</Text>
            </Menu.Item>
          )}
        </DumpDeleteAction>
      </Menu>
    </div>
  );
};

export default RowContextMenuComponent;
