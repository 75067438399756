import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';
import { Text } from 'components/common/ComponentLibrary';

const EmptyListComponent = () => {
  const location = useLocation();
  return (
    <div className='flex flex-col flex-1 justify-center'>
      <Text size='xxl' color='gray-500' textAlign='center' spacing='s'>
        Your dumps will be listed here
      </Text>
      <Link
        to={getUrlHome()}
        state={{ previousRoute: location.pathname }}
        className='flex flex-col gap-5 items-center justify-center'
      >
        <Text size='xxl' color='cyan' textAlign='center'>
          Start writing...
        </Text>
      </Link>
    </div>
  );
};

export default EmptyListComponent;
