import { IDump } from 'models/dump.model';
import { RxDocument } from 'rxdb';
import { DumpDocType } from './dump.schema';
import { extractTags } from 'models/dumps/services/rxDb/RxDbDumpService';

export type DumpDocumentMethods = {
  asDump: () => IDump;
};

export type DumpDocument = RxDocument<DumpDocType, DumpDocumentMethods>;

export const defaultDumpDocumentMethods: DumpDocumentMethods = {
  asDump: function (this: DumpDocument) {
    return {
      id: this.id,
      text: this.text,
      tags: extractTags(this.text) ?? [],
      location: this.location,
      updated_at: this.updated_at,
      created_at: this.created_at,
      last_edited_at: this.last_edited_at,
      user_id: this.user_id,
      reminder: this.reminder,
      _deleted: this._deleted,
      is_pinned: this.is_pinned,
    };
  },
};
