import { ReactNode } from 'react';
import { updateDump } from 'models/dumps/services/rxDb/RxDbDumpService';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';

interface IDumpPinAction {
  dump: DumpInterface;
  children: (props: { pinDump: () => void }) => ReactNode;
}

const DumpPinAction = ({ children, dump }: IDumpPinAction) => {
  const handlePinDump = async () => {
    await updateDump(dump.id, { ...dump, is_pinned: !dump.is_pinned }, false);
  };

  return children?.({
    pinDump: handlePinDump,
  });
};

export default DumpPinAction;
