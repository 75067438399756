import { HighlightedText } from 'components/common/HighlightedText.util';
import { IDump } from 'models/dump.model';
import React from 'react';
import { Text } from 'components/common/ComponentLibrary';
import { isFilePathAudio, isFilePathImage, isFilePathVideo } from 'utilities/storage.utils';
import SupabaseImageAttachment from 'components/common/SupabaseImageAttachment';
import { STORAGE_BUCKET_ENUM } from 'models/application/enums/ImageEnums';
import DumpRowImagePreview from './DumpRowImagePreview';
import DumpRowVideoPreview from './DumpRowVideoPreview';
import DumpRowAudioPreview from './DumpRowAudioPreview';
import SupabaseVideoAttachment from 'components/common/SupabaseVideoAttachment';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { useSettingsStore } from 'store/setting.store';
import { DENSITY } from 'constants/density.constants';

type Props = {
  searchTerm: string;
  dump: IDump;
  attachments?: AttachmentInterface[] | null;
};

const getLineClamp: any = (density: DENSITY) => {
  if (density === DENSITY.ALL) {
    return 'none';
  }
  if (density === DENSITY.COMPACT) {
    return 2;
  }

  return 6;
};

const DumpRowContents: React.FC<Props> = (props) => {
  const { dump, attachments, searchTerm } = props;

  const [settings] = useSettingsStore((state) => [state.settings]);

  return (
    <div>
      {attachments && attachments.length > 0 && !dump.text && (
        <div className='flex items-center gap-2'>
          {attachments.map((attachment) => {
            if (isFilePathImage(attachment.file_path)) {
              return (
                <SupabaseImageAttachment
                  key={attachment.id}
                  path={attachment.file_path}
                  storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                >
                  {({ previewSrc, src }) => <DumpRowImagePreview previewSrc={previewSrc || src} />}
                </SupabaseImageAttachment>
              );
            }

            if (isFilePathVideo(attachment.file_path)) {
              return (
                <SupabaseVideoAttachment
                  key={attachment.id}
                  path={attachment.file_path}
                  storageBucket={STORAGE_BUCKET_ENUM.ATTACHMENTS}
                >
                  {({ previewSrc }) => <DumpRowVideoPreview previewSrc={previewSrc} />}
                </SupabaseVideoAttachment>
              );
            }

            if (isFilePathAudio(attachment.file_path)) {
              return <DumpRowAudioPreview key={attachment.id} />;
            }

            return null;
          })}
        </div>
      )}
      {dump.text && (
        <div className='w-full whitespace-pre-wrap break-all'>
          {searchTerm.length > 0 ? (
            <HighlightedText text={dump.text} highlight={searchTerm} />
          ) : (
            <Text lineClamp={getLineClamp(settings?.asSettings()?.density)}>{dump.text}</Text>
          )}
        </div>
      )}
    </div>
  );
};

export default DumpRowContents;
