import { useEffect } from 'react';
import { THEME } from 'constants/theme.constants';
import { useSettingsStore } from 'store/setting.store';

const useTheme = () => {
  const [settings, updateSettings] = useSettingsStore((state) => [state.settings, state.updateSettings]);

  const handleSetTheme = async (theme: THEME) => {
    document.body.classList.remove(settings?.asSettings()?.theme as string);
    await updateSettings({ theme });
  };

  useEffect(() => {
    if (settings) {
      if (
        settings.asSettings().theme === THEME.DARK ||
        (settings.asSettings().theme === THEME.SYSTEM && window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.body.classList.add(THEME.DARK);
      } else if (settings.asSettings().theme === THEME.LIGHT) {
        document.body.classList.add(THEME.LIGHT);
      }
    }
  }, [settings]);

  return { setTheme: handleSetTheme, theme: settings?.asSettings()?.theme };
};

export default useTheme;
