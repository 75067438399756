export type ColorName =
  | 'black'
  | 'dark'
  | 'bright'
  | 'blue'
  | 'cyan'
  | 'green'
  | 'magenta'
  | 'red'
  | 'white'
  | 'yellow';
export type Color = {
  readonly name: ColorName;
  readonly hexCode: string;
  readonly borderClass: string;
  readonly bgClass: string;
  readonly textClass: string;
  readonly strokeClass: string;
  readonly fillClass: string;

  alpha: (opacity: number) => string;
};

export const COLORS: { [color: string]: Color } = {
  cyan: {
    name: 'cyan',
    hexCode: '#5ccabe',
    bgClass: 'bg-cyan',
    borderClass: 'border-cyan',
    strokeClass: 'stroke-cyan',
    fillClass: 'fill-cyan',
    textClass: 'text-cyan',
    alpha: (alpha) => `rgba(92,202,190,${alpha})`,
  },
  blue: {
    name: 'blue',
    hexCode: '#5071a1',
    bgClass: 'bg-blue',
    borderClass: 'border-blue',
    textClass: 'text-blue',
    strokeClass: 'stroke-blue',
    fillClass: 'fill-blue',
    alpha: (alpha) => `rgba(80,113,161,${alpha})`,
  },
  bright: {
    name: 'bright',
    hexCode: '#f1f0eb',
    bgClass: 'bg-bright',
    borderClass: 'border-bright',
    textClass: 'text-bright',
    strokeClass: 'stroke-bright',
    fillClass: 'fill-bright',
    alpha: (alpha) => `rgba(241,240,235,${alpha})`,
  },
  green: {
    name: 'green',
    hexCode: '#71b468',
    bgClass: 'bg-green',
    borderClass: 'border-green',
    textClass: 'text-green',
    strokeClass: 'stroke-green',
    fillClass: 'fill-green',
    alpha: (alpha) => `rgba(113,180,104,${alpha})`,
  },
  red: {
    name: 'red',
    hexCode: '#ee6022',
    bgClass: 'bg-red',
    borderClass: 'border-red',
    textClass: 'text-red',
    strokeClass: 'stroke-red',
    fillClass: 'fill-red',
    alpha: (alpha) => `rgba(238,96,34,${alpha})`,
  },
  magenta: {
    name: 'magenta',
    hexCode: '#CD1D95',
    bgClass: 'bg-magenta',
    borderClass: 'border-magenta',
    textClass: 'text-magenta',
    strokeClass: 'stroke-magenta',
    fillClass: 'fill-magenta',
    alpha: (alpha) => `rgba(205,29,149,${alpha})`,
  },
  yellow: {
    name: 'yellow',
    hexCode: '#f5b81c',
    bgClass: 'bg-yellow',
    borderClass: 'border-yellow',
    textClass: 'text-yellow',
    strokeClass: 'stroke-yellow',
    fillClass: 'fill-yellow',
    alpha: (alpha) => `rgba(245,184,28,${alpha})`,
  },
};
