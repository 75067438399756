import React from 'react';

import { CountrySelector, usePhoneInput } from 'react-international-phone';
import { Button, Input } from 'components/common/ComponentLibrary';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import { toast } from 'react-toastify';
import { useProfileStore } from 'store/profile.store';
import { captureMessage } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import 'react-international-phone/style.css';
import { notifyAboutError } from '../../../utilities/common/notifyAboutError.utils';

const ProfilePhoneForm = ({ onChanged }: { onChanged: () => void }) => {
  const { t } = useTranslation('translations');
  const [profile, updateProfile] = useProfileStore((state) => [state.profile, state.updateProfile]);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const { country, setCountry, phone, handlePhoneValueChange } = usePhoneInput({
    forceDialCode: true,
    value: profile?.asProfile().phone || '',
    defaultCountry: 'us',
  });

  const handleUpdatePhone = async () => {
    if (!phone || phone.length <= 5) {
      await updateProfile({ phone: null });
      onChanged();
      return;
    }

    if (!phone || !phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))) {
      toast.error('Invalid phone number');
      captureMessage('Invalid phone number');
      return;
    }

    const number = phoneUtil.parseAndKeepRawInput(phone, country.name);
    await updateProfile({ phone: phoneUtil.format(number, PhoneNumberFormat.E164) });
    onChanged();
  };

  return (
    <div className='flex flex-col space-y-6'>
      <div className='flex items-center flex-row row space-x-4'>
        <CountrySelector
          selectedCountry={country.iso2}
          onSelect={({ iso2 }) => setCountry(iso2)}
          className='rounded-full border-0 bg-transparent hover:bg-transparent focus:ring-cyan'
          buttonClassName='rounded-full border-0 bg-transparent hover:bg-transparent focus:ring-cyan'
        />
        <Input onChange={handlePhoneValueChange} value={phone} />
      </div>
      <div className='flex justify-between'>
        <Button size='m' onClick={onChanged} theme='ghost'>
          {t('phoneModal.rejectAction')}
        </Button>
        <Button size='m' type='submit' onClick={handleUpdatePhone} status={phone ? '' : 'disabled'}>
          {t('phoneModal.acceptAction')}
        </Button>
      </div>
    </div>
  );
};

export default ProfilePhoneForm;
