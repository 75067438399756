import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

export const dumpSchemaLiteral = {
  title: 'dumpit dump schema',
  version: 1,
  description: 'describes a dump',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      minLength: 36,
      maxLength: 36,
    },
    user_id: {
      type: 'string',
      minLength: 36,
      maxLength: 36,
    },
    text: {
      type: 'string',
    },
    location: {
      type: 'object',
      properties: {
        altitude: {
          type: 'number',
        },
        latitude: {
          type: 'number',
        },
        longitude: {
          type: 'number',
        },
      },
    },
    reminder: {
      type: 'string',
    },
    created_at: {
      type: 'string',
      final: true,
    },
    updated_at: {
      type: 'string',
    },
    last_edited_at: {
      type: 'string',
    },
    _deleted: {
      type: 'boolean',
    },
    is_pinned: {
      type: 'boolean',
    },
  },
  required: ['id', 'created_at', 'updated_at'],
} as const;

const schemaTyped = toTypedRxJsonSchema(dumpSchemaLiteral);
export type DumpDocType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const dumpSchema: RxJsonSchema<DumpDocType> = dumpSchemaLiteral;
