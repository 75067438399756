import React from 'react';

import classNames from 'classnames';

interface ToggleInterface {
  name: string;
  value: boolean;
  size?: 'm' | 'l';
  status?: 'disabled' | 'invalid' | '';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onBlur?: () => any;
}

const Toggle = ({ name, size = 'm', onChange, onBlur, status, value }: ToggleInterface) => {
  const toggleClassnames = classNames({
    'rounded-full flex items-center px-1 transition-all ease-in-out': true,
    'bg-gray-200 dark:bg-dark border-gray-200 dark:border-paperdark justify-start': !value,
    'bg-cyan border-bright dark:border-paperdark justify-end': value,
    'w-10 h-6': size === 'm',
    'w-12 h-7': size === 'l',
    'opacity-50 cursor-not-allowed': status === 'disabled',
  });

  const toggleSwitchClassName = classNames({
    'rounded-full': true,
    'bg-white dark:bg-paperdark': value,
    'bg-white': !value,
    'w-4 h-4': size === 'm',
    'w-5 h-5': size === 'l',
  });

  return (
    <label htmlFor={name} className='relative flex cursor-pointer items-center'>
      <input
        id={name}
        name={name}
        type='checkbox'
        disabled={status === 'disabled'}
        onChange={onChange}
        onBlur={onBlur}
        className='hidden'
        checked={value}
      />
      <div className={toggleClassnames}>
        <div className={toggleSwitchClassName} />
      </div>
    </label>
  );
};

export default Toggle;
