import React, { forwardRef } from 'react';

import classNames from 'classnames';

interface IHyperlink {
  children: React.ReactNode;
  size?: 'inherit' | 's' | 'm' | 'l';
  theme?: 'bare' | 'decorated' | 'silent';
  color?: 'default' | 'inherit';
  href: string;
  target?: string;
}

const Hyperlink = forwardRef<HTMLAnchorElement, IHyperlink>(
  (
    { children, color = 'inherit', theme = 'bare', size = 'inherit', ...others }: IHyperlink,
    ref,
  ) => {
    const linkClassnames = classNames('cursor-pointer inline-flex', {
      'border-b border-gray-200': theme === 'decorated',
      'text-sm': size === 's',
      'text-base': size === 'm',
      'text-lg': size === 'l',
      'text-cyan': color === 'default',
    });

    return (
      <a className={linkClassnames} href={others.href} ref={ref} target={others.target}>
        {children}
      </a>
    );
  },
);

Hyperlink.displayName = 'Hyperlink';

export default Hyperlink;
