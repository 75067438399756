import { HighlightedText } from 'components/common/HighlightedText.util';
import React, { ComponentPropsWithoutRef, useState } from 'react';
import { COLORS } from 'themes/colors';

type Props = {
  tag: string;
  searchTerm: string;
  onToggleTag: (searchTerm: string) => void;
} & ComponentPropsWithoutRef<'li'>;

const TagItemComponent: React.FC<Props> = (props) => {
  const { tag, searchTerm, onToggleTag, className = '', ...liProps } = props;
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <li key={tag} className={`${className}`} {...liProps}>
      <button
        onClick={(e) => {
          e.preventDefault();
          onToggleTag(searchTerm === tag ? '' : tag);
        }}
        className={`px-4 py-2 ${
          isActive || isHovered || searchTerm.toLowerCase() === tag.toLowerCase()
            ? `${COLORS.cyan.name}-selective`
            : 'default-selective'
        } transition-all duration-200 ease-in-out rounded-xl focus-within:bg-dark/15 focus-within:dark:bg-white/15 focus-within:outline-0 focus-within:ring-0 focus-within:outline-none`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onBlur={() => setIsActive(false)}
        onFocus={() => setIsActive(true)}
      >
        #{searchTerm.length > 0 ? <HighlightedText text={tag} highlight={searchTerm} /> : tag}
      </button>
    </li>
  );
};

export default TagItemComponent;
