import Resizer from 'react-image-file-resizer';
import { captureException } from '@sentry/react';

export function getFileExtension(filename: string): string {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return '';
  }

  return filename.substring(lastDotIndex + 1);
}

export const resizeFile = (file: File): Promise<File | null> => {
  try {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        getFileExtension(file.name),
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file',
      );
    });
  } catch (error) {
    captureException(error);
    return Promise.reject(error);
  }
};

export const getFileSizeMB = (size: number): number => {
  return size / 1000 / 1000;
};

const checkType = (file: File, types: string[]): boolean => {
  const extension: string = file.name.split('.').pop() as string;
  const loweredTypes = types.map((type) => type.toLowerCase());
  return loweredTypes.includes(extension.toLowerCase());
};

export const validateFile = (file: File, options: { allowedTypes: string[]; maxSize: number }) => {
  if (options.allowedTypes && !checkType(file, options.allowedTypes)) {
    return { name: 'FileTypeError' };
  }

  if (options.maxSize && getFileSizeMB(file.size) > options.maxSize) {
    return { name: 'FileSizeError' };
  }

  return;
};
