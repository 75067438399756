import { v4 as uuidv4 } from 'uuid';
import { ILocation } from './location.model';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';

export interface IDump {
  id: string;
  text?: string;
  user_id?: string;
  location?: ILocation | null;
  tags?: string[];
  updated_at: string;
  created_at: string;
  last_edited_at?: string;
  reminder?: string;
  _deleted?: boolean;
  is_pinned?: boolean;
}

export class Dump implements IDump {
  readonly id: string;
  text?: string;
  tags?: string[];
  location?: ILocation | null;
  updated_at: string;
  created_at: string;
  user_id?: string;
  reminder?: string;
  _deleted?: boolean;
  is_pinned?: boolean;

  constructor(dump: IDump) {
    this.id = dump.id;
    this.text = dump.text;
    this.tags = dump.tags;
    this.location = dump.location;
    this.updated_at = dump.updated_at;
    this.created_at = dump.created_at;
    this.user_id = dump.user_id;
    this.reminder = dump.reminder;
    this._deleted = dump._deleted;
    this.is_pinned = dump.is_pinned;
  }

  static new = (text = '') =>
    new Dump({
      id: uuidv4().toString(),
      text,
      created_at: getCurrentUTCDateFormatted(),
      updated_at: getCurrentUTCDateFormatted(),
    });
}

export const dumpTextContains = (dump: IDump, find: string) =>
  (dump.text?.toLowerCase() ?? '').indexOf(find.toLowerCase()) >= 0;

export const dumpTagContains = (dump: IDump, find: string) => {
  if (!dump.tags) {
    return false;
  }

  return dump?.tags.filter((tag) => tag.toLowerCase().indexOf(find.toLowerCase()) >= 0).length > 0;
};
