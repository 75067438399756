import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { dumpitDatabase } from './rxdb/dumpit.database';
import { SettingDocument } from './rxdb/settings/setting.document';
import { SettingInterface } from 'models/settings/interfaces/SettingInterface';

export type ISettings = {
  settings: SettingDocument | null;
  isGlobalSearchVisible: boolean;
  setGlobalSearchVisibility: (isVisible: boolean) => void;

  getSettings: () => Promise<SettingDocument | null>;
  setSettings: (settings: SettingDocument | null) => void;

  updateSettings: (data: Partial<SettingInterface>) => Promise<void>;
};

export const useSettingsStore = create<ISettings>((set) => ({
  settings: null,

  isGlobalSearchVisible: false,

  setGlobalSearchVisibility: (isVisible) =>
    set((state) => ({
      ...state,
      isGlobalSearchVisible: isVisible,
    })),

  getSettings: async () => {
    try {
      const settings = await dumpitDatabase.settings.findOne().exec();
      return settings as unknown as SettingDocument;
    } catch (e) {
      return null;
    }
  },

  setSettings: (settings) => {
    set((state) => ({
      ...state,
      settings,
    }));
  },

  updateSettings: async (settings: Partial<SettingInterface>) => {
    try {
      const query = await dumpitDatabase.settings.findOne().exec();
      const updatedDocument = await query?.update({
        $set: {
          ...settings,
        },
      });

      set((state) => ({
        ...state,
        settings: updatedDocument as unknown as SettingDocument,
      }));
    } catch (e) {
      return undefined;
    }
  },
}));

mountStoreDevtool('SettingsStore', useSettingsStore);
