import { dumpitDatabase } from 'store/rxdb/dumpit.database';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { uniquifyArray } from 'utilities/array.utils';

export async function createDump(dump: DumpInterface) {
  const newDump: DumpInterface = {
    ...dump,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    last_edited_at: getCurrentUTCDateFormatted(),
  };

  if (dump.location) {
    newDump.location = {};
    newDump.location.latitude = dump.location.latitude;
    newDump.location.longitude = dump.location.longitude;
    if (dump.location.altitude) {
      newDump.location.altitude = dump.location.altitude;
    }
  } else {
    newDump.location = undefined;
  }

  delete newDump.tags; // @todo-phil We should not add tags.

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // @todo-phil I could not fix this TS issue :(
  return await dumpitDatabase.dumps.insert(newDump);
}

export async function updateDump(dumpId: string, dump: Partial<DumpInterface>, updateLastEditedAt = true) {
  try {
    const dumpDoc = await dumpitDatabase.dumps.findOne({ selector: { id: dumpId } }).exec();
    if (!dumpDoc) {
      return Promise.reject('No dump found');
    }

    const updatedDoc = {
      ...dump,
      updated_at: getCurrentUTCDateFormatted(),
    };
    if (updateLastEditedAt) {
      updatedDoc.last_edited_at = getCurrentUTCDateFormatted();
    }

    if (dump.location) {
      updatedDoc.location = {};
      updatedDoc.location.latitude = dump.location.latitude;
      updatedDoc.location.longitude = dump.location.longitude;
      if (dump.location.altitude) {
        updatedDoc.location.altitude = dump.location.altitude;
      }
    } else {
      updatedDoc.location = null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete updatedDoc.attachments; // @todo-phil Temp to remove a field that should not be there.

    delete updatedDoc.tags; // @todo-phil We should not add tags.

    await dumpDoc.getLatest().update({
      $set: updatedDoc,
    });

    return Promise.resolve();
  } catch (e) {
    const dumpDoc = await dumpitDatabase.dumps.findOne({ selector: { id: dumpId } }).exec();
    console.log(2, dumpDoc);
    return Promise.reject(e);
  }
}

export async function deleteDump(dumpId: string) {
  try {
    const dumpDoc = await dumpitDatabase.dumps.findOne({ selector: { id: dumpId } }).exec();
    if (!dumpDoc) {
      return Promise.reject('No dump found');
    }

    await dumpDoc?.update({
      $set: {
        _deleted: true,
        updated_at: getCurrentUTCDateFormatted(),
      },
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function undoDeleteDump(dump: DumpInterface) {
  try {
    let dumpDoc = await dumpitDatabase.dumps.findOne({ selector: { id: dump.id } }).exec();
    if (!dumpDoc) {
      const newDump: DumpInterface = {
        ...dump,
        created_at: dump.created_at,
        updated_at: dump.updated_at,
        last_edited_at: dump.last_edited_at,
      };

      if (dump.location) {
        newDump.location = {};
        newDump.location.latitude = dump.location.latitude;
        newDump.location.longitude = dump.location.longitude;
        newDump.location.altitude = dump.location.altitude;
      } else {
        newDump.location = undefined;
      }

      delete newDump.tags; // @todo-phil We should not add tags.

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dumpDoc = await dumpitDatabase.dumps.insert(newDump);
    }

    await dumpDoc?.update({
      $set: {
        _deleted: false,
        updated_at: getCurrentUTCDateFormatted(),
      },
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getDumpById(dumpId: string) {
  try {
    const dump = await dumpitDatabase.dumps
      .findOne({
        selector: {
          id: dumpId,
        },
      })
      .exec();
    if (!dump) {
      return Promise.reject('No dump found');
    }

    return Promise.resolve(dump);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAllDumps() {
  try {
    return await dumpitDatabase.dumps.queryAll();
  } catch (e) {
    return Promise.reject(e);
  }
}

export const extractTags = (text?: string) => {
  if (!text || text.length <= 1) {
    return [];
  }

  const hashtagRegex = /(?<=^|\s)#([\p{L}\p{N}]{1,29})(?=\s|$|\W)/gu;
  const matches = text.match(hashtagRegex);

  if (!matches) {
    return [];
  }

  return uniquifyArray(matches.map((tag) => tag.slice(1)));
};
