import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LocationInterface } from 'models/dumps/interfaces/LocationInterface';

const useGeolocation = () => {
  const [location, setLocation] = useState<LocationInterface | null>(null);
  const [hasAccess, setHasAccess] = useState<boolean>(false);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          setHasAccess(true);
        } else if (result.state === 'prompt') {
          // User will be prompted once you try to access geolocation features
        } else {
          setHasAccess(false);
        }
      });
    } else {
      setHasAccess(false);
    }
  }, []);

  const handleGetCurrentPosition = async (): Promise<LocationInterface | null> => {
    try {
      const result: GeolocationPosition = await new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          return reject('Location not supported by your browser.');
        }

        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      return {
        altitude: result.coords.altitude || undefined,
        latitude: result.coords.latitude,
        longitude: result.coords.longitude,
      };
    } catch (error) {
      return null;
    }
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      const defaultOptions = {
        maximumAge: Infinity,
        timeout: 2000,
        enableHighAccuracy: false,
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            altitude: position.coords.altitude || undefined,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          setLocation(location);
        },
        (error) => {
          toast.info(`Location permission is denied. Please enable it in your browser settings.`);
        },
        defaultOptions,
      );
    } else {
      toast.info('Location not supported by your browser.');
    }
  };

  return { location, hasAccess, getLocation: handleGetLocation, getCurrentPosition: handleGetCurrentPosition };
};

export default useGeolocation;
