import React, { useState, useRef, useEffect } from 'react';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/outline';
import { Button, Text } from 'components/common/ComponentLibrary';
import AudioPlayerPlayspeed from './AudioPlayerPlayspeed';
import usePlayspeed from 'hooks/application/usePlayspeed';
import './AudioPlayer.css';

// https://timetoprogram.com/build-audio-player-in-react/
const AudioPlayer = ({ src }: { src: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  const { playspeed } = usePlayspeed();

  const handlePlay = () => {
    audioRef?.current?.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    audioRef?.current?.pause();
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef?.current) {
      setCurrentTime(audioRef?.current?.currentTime);
      setDuration(audioRef?.current?.duration);
    }
  };

  const handleSeek = (e: any) => {
    if (audioRef?.current?.currentTime) {
      audioRef.current.currentTime = e.target.value;
    }
    setCurrentTime(e.target.value);
  };

  function formatDuration(durationSeconds: number) {
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = Math.floor(durationSeconds % 60);
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${minutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    setTimeout(() => {
      handlePlay();
    }, 100);

    audioRef?.current?.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      audioRef?.current?.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (currentTime >= duration) {
      setIsPlaying(false);
    }
  }, [currentTime]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.playbackRate = playspeed;
    }
  }, [playspeed]);

  return (
    <div className='w-64 bg-neutral-100 dark:bg-neutral-700 border border-dark/10 dark:border-0 rounded-2xl p-4'>
      <input type='range' min='0' max={Math.floor(duration)} value={currentTime} onChange={handleSeek} />

      <audio ref={audioRef} src={src} />

      <div className='pb-4 flex items-center justify-between'>
        <Text size='s' color='gray-500'>
          {formatDuration(currentTime)}
        </Text>
        <AudioPlayerPlayspeed />
        <Text size='s' color='gray-500'>
          {formatDuration(duration - currentTime)}
        </Text>
      </div>

      <div className='flex items-center justify-center'>
        {isPlaying ? (
          <Button width='square' onClick={handlePlayPause}>
            <Button.Icon icon={<PauseIcon className='w-5' />} />
          </Button>
        ) : (
          <Button width='square' onClick={handlePlayPause}>
            <Button.Icon icon={<PlayIcon className='w-5' />} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;
