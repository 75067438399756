import { useEffect, useState } from 'react';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { getAttachmentsByDumpId } from 'models/dumps/services/rxDb/RxDbAttachmentService';
import { AttachmentDocument } from 'store/rxdb/attachment/attachment.document';
import { dumpitDatabase } from 'store/rxdb/dumpit.database';

const useDumpAttachments = (dumpId: string) => {
  const [docAttachments, setDocAttachments] = useState<AttachmentDocument[] | undefined>(undefined);
  const [attachments, setAttachments] = useState<AttachmentInterface[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const attachments = await getAttachmentsByDumpId(dumpId);
        if (attachments) {
          setDocAttachments(attachments);
          setAttachments(attachments.map((_attachment) => _attachment.asAttachment()));
        }
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
      }
    })();

    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (dumpId) {
      const query = dumpitDatabase.attachments.find().where('dump_id').eq(dumpId);
      const sub = query.$.subscribe((attachments: any) => {
        setDocAttachments(attachments);
      });

      return () => {
        sub?.unsubscribe();
      };
    }
  }, [dumpId]);

  useEffect(() => {
    if (docAttachments) {
      const subs = docAttachments.map((docAttachment) => {
        return docAttachment.$.subscribe((current) => {
          setAttachments((prevAttachments) => {
            if (!prevAttachments) return prevAttachments;

            const currentAttachment = current.asAttachment();
            const index = prevAttachments.findIndex((att) => att.id === currentAttachment.id);

            if (currentAttachment._deleted) {
              if (index !== -1) {
                const newAttachments = [...prevAttachments];
                newAttachments.splice(index, 1);
                return newAttachments;
              }
            } else {
              if (index !== -1) {
                const newAttachments = [...prevAttachments];
                newAttachments[index] = currentAttachment;
                return newAttachments;
              } else {
                return [...prevAttachments, currentAttachment];
              }
            }

            return prevAttachments;
          });
        });
      });

      return () => {
        subs.forEach((sub) => sub.unsubscribe());
      };
    }
  }, [docAttachments]);

  return {
    attachments,
    isLoading,
  };
};

export default useDumpAttachments;
