import { dumpitDatabase } from 'store/rxdb/dumpit.database';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { FeedbackInterface } from 'models/feedback/interfaces/FeedbackInterface';
import { v4 as uuidv4 } from 'uuid';

export async function createFeedback(description: string, userId: string) {
  const newFeedback: FeedbackInterface = {
    id: uuidv4().toString(),
    description,
    user_id: userId,
    platform: 'web',
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // @todo-phil I could not fix this TS issue :(
  return await dumpitDatabase.feedback.insert(newFeedback);
}
