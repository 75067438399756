import { RxDocument } from 'rxdb';
import { SettingDocumentType } from './setting.schema';
import { SettingInterface } from 'models/settings/interfaces/SettingInterface';
import { PLAYSPEED } from 'constants/playspeed.constants';
import { DENSITY } from 'constants/density.constants';

export type SettingsDocumentMethods = {
  asSettings: () => SettingInterface;
};
export type SettingDocument = RxDocument<SettingDocumentType, SettingsDocumentMethods>;

export const defaultSettingsDocumentMethods: SettingsDocumentMethods = {
  asSettings: function (this: SettingDocument) {
    return {
      id: this.id,
      theme: this.theme,
      playspeed: this.playspeed || PLAYSPEED.NORMAL,
      density: this.density || DENSITY.DEFAULT,
      show_placeholder: this.show_placeholder ?? true,
    };
  },
};
