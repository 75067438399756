import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button, Hyperlink, Text } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import supabase from 'store/supabase/supabase';
import { useNavigate } from 'react-router-dom';
import { getUrlCookies, getUrlPrivacy, getUrlTerms, getUrlVerifyEmail } from 'utilities/urls.utils';
import {
  FIRSTNAME_MAX_LENGTH,
  FIRSTNAME_MIN_LENGTH,
  LASTNAME_MAX_LENGTH,
  LASTNAME_MIN_LENGTH,
  MINIMUM_PW_LENGTH,
} from 'constants/profiles.constants';
import { trackEvent } from 'models/application/services/TrackingService';
import { TRACKING_EVENT_ENUM } from 'models/application/enums/trackingEventEnum';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import { SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED } from 'constants/supabase.constants';
import { SIGNUPS_INVITE_ONLY } from 'constants/application.constants';

const validationSchema = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .min(FIRSTNAME_MIN_LENGTH, `Firstname should be at least ${FIRSTNAME_MIN_LENGTH} characters`)
    .max(FIRSTNAME_MAX_LENGTH, `Firstname should be at most ${FIRSTNAME_MAX_LENGTH} characters`)
    .required('Enter your first name'),
  lastname: yup
    .string()
    .trim()
    .min(LASTNAME_MIN_LENGTH, `Lastname should be at least ${LASTNAME_MIN_LENGTH} characters`)
    .max(LASTNAME_MAX_LENGTH, `Lastname should be at most ${LASTNAME_MAX_LENGTH} characters`)
    .required('Enter your last name'),
  email: yup.string().email().trim().required('Enter your email'),
  password: yup
    .string()
    .trim()
    .min(MINIMUM_PW_LENGTH, `Password should be at least ${MINIMUM_PW_LENGTH} characters`)
    .required('Enter a password'),
});

const SignUpForm = () => {
  const navigate = useNavigate();

  return (
    <Formik
      onSubmit={async ({ email, password, firstname, lastname }) => {
        const { error } = await supabase.auth.signUp({
          email: email.toLowerCase(),
          password,
          options: {
            data: {
              full_name: `${firstname} ${lastname}`,
            },
          },
        });
        if (error) {
          notifyAboutError(
            error,
            true,
            error.message === SUPABASE_ERROR_DESCRIPTION_SIGNUPS_NOT_ALLOWED ? SIGNUPS_INVITE_ONLY : error.message,
          );
          return;
        }

        trackEvent(TRACKING_EVENT_ENUM.SIGN_UP, {
          provider: 'email',
        });

        navigate(getUrlVerifyEmail(email));
      }}
      initialValues={{ email: '', password: '', firstname: '', lastname: '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-5 w-full'>
            <div className='flex md:space-x-5 space-y-5 md:space-y-0 flex-col md:flex-row'>
              <FormikInputField name='firstname' placeholder='Your first name' type='text' />
              <FormikInputField name='lastname' placeholder='Your last name' type='text' />
            </div>
            <FormikInputField type='email' name='email' placeholder='Email e.g. email@example.com' />
            <FormikInputField type='password' name='password' placeholder='Enter a password' />
            <div className='mt-4'>
              <Text size='s' tag='div' color='gray-500'>
                By signing up, you agree to our{' '}
                <Hyperlink color='default' href={getUrlTerms()}>
                  terms of service
                </Hyperlink>{' '}
                and{' '}
                <Hyperlink color='default' href={getUrlPrivacy()}>
                  privacy policy
                </Hyperlink>
                , including{' '}
                <Hyperlink color='default' href={getUrlCookies()}>
                  cookie use
                </Hyperlink>
                .
              </Text>
            </div>
            <div className='flex justify-end'>
              <Button size='l' type='submit' status={isSubmitting ? 'busy' : ''} width='full'>
                Sign up
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
