import { useEffect, useState } from 'react';
import { IDumpitDatabase, dumpitDatabase } from 'store/rxdb/dumpit.database';

const useRxDb = () => {
  const [db, setDb] = useState<IDumpitDatabase | undefined>();

  useEffect(() => {
    setDb(dumpitDatabase);
  }, []);

  return { db };
};

export default useRxDb;
