import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { deleteAttachment } from 'models/dumps/services/rxDb/RxDbAttachmentService';
import { IDraftAttachment } from 'store/dumpDraft.store';

export const processRemovedAttachments = async (
  initialAttachments?: AttachmentInterface[],
  attachments?: AttachmentInterface[] | IDraftAttachment[],
) => {
  let removedAttachments: AttachmentInterface[] | IDraftAttachment[] = [];

  if (initialAttachments && initialAttachments.length > 0) {
    if (attachments && attachments.length > 0) {
      removedAttachments = initialAttachments.filter(
        (initialAttachment) =>
          !attachments.some(
            (attachment: AttachmentInterface | IDraftAttachment) =>
              'id' in attachment && attachment.id === initialAttachment.id,
          ),
      );
    } else {
      removedAttachments = initialAttachments; // All initial attachments have been removed since attachments is empty
    }
  } else {
    removedAttachments = []; // initialAttachments is empty, so there are no removed attachments
  }
  if (removedAttachments.length === 0) {
    return;
  }

  await Promise.all(
    removedAttachments?.map(async (removedAttachment: AttachmentInterface | IDraftAttachment) => {
      if ('id' in removedAttachment) {
        await deleteAttachment(removedAttachment.id);
      }
    }),
  );
};

export const sortDumps = (dumps: DumpInterface[]): DumpInterface[] => {
  return dumps.sort((a: DumpInterface, b: DumpInterface) => {
    const dateA = a.last_edited_at ? new Date(a.last_edited_at) : new Date(a.updated_at);
    const dateB = b.last_edited_at ? new Date(b.last_edited_at) : new Date(b.updated_at);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    const textA = a.text || '';
    const textB = b.text || '';

    return textA.localeCompare(textB, undefined, { numeric: true });
  });
};
