export const LOCAL_STORAGE_LOCATION_ENABLED = 'locationEnabled';

const LOCAL_STORAGE_ITEM_VALUE_ON = 'on';
const LOCAL_STORAGE_ITEM_VALUE_OFF = 'off';

export function isLocationEnabled(): boolean {
  const setting = localStorage.getItem(LOCAL_STORAGE_LOCATION_ENABLED);

  return setting === LOCAL_STORAGE_ITEM_VALUE_ON;
}

export function toggleLocationEnabled(isActive: boolean): void {
  if (isActive) {
    localStorage.setItem(LOCAL_STORAGE_LOCATION_ENABLED, LOCAL_STORAGE_ITEM_VALUE_ON);
  } else {
    localStorage.setItem(LOCAL_STORAGE_LOCATION_ENABLED, LOCAL_STORAGE_ITEM_VALUE_OFF);
  }
}
