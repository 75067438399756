import React from 'react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Button, Tooltip, Menu, Text } from 'components/common/ComponentLibrary';
import { PLAYSPEED } from 'constants/playspeed.constants';
import usePlayspeed from 'hooks/application/usePlayspeed';

const renderPlayspeed = (playspeed: PLAYSPEED) => {
  return `${playspeed.toFixed(2)}×`;
};

const AudioPlayspeedSelector = () => {
  const { playspeed, setPlayspeed } = usePlayspeed();

  return (
    <Tooltip
      width='full'
      size='m'
      spacing='none'
      target={
        <Button theme='bare' width='none' size='m'>
          {renderPlayspeed(playspeed)}
          <Button.Icon icon={<ChevronDownIcon className='w-5' />} />
        </Button>
      }
      isInteractive={true}
      hideOnClick={true}
      scope='local'
      trigger='click'
    >
      <Menu>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.SLOWEST)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.SLOWEST)}</Text>
          {playspeed === PLAYSPEED.SLOWEST && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.SLOWER)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.SLOWER)}</Text>
          {playspeed === PLAYSPEED.SLOWER && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.SLOW)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.SLOW)}</Text>
          {playspeed === PLAYSPEED.SLOW && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.NORMAL)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.NORMAL)}</Text>
          {playspeed === PLAYSPEED.NORMAL && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.FAST)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.FAST)}</Text>
          {playspeed === PLAYSPEED.FAST && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.FASTER)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.FASTER)}</Text>
          {playspeed === PLAYSPEED.FASTER && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.EVEN_FASTER)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.EVEN_FASTER)}</Text>
          {playspeed === PLAYSPEED.EVEN_FASTER && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => setPlayspeed(PLAYSPEED.FASTEST)}>
          <Text color='inherit'>{renderPlayspeed(PLAYSPEED.FASTEST)}</Text>
          {playspeed === PLAYSPEED.FASTEST && <CheckIcon className='w-5' />}
        </Menu.Item>
      </Menu>
    </Tooltip>
  );
};

export default AudioPlayspeedSelector;
