import { ILocation } from '../models/location.model';

export const getCurrentLocation = async (): Promise<ILocation | null> => {
  try {
    const result: GeolocationPosition = await new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        return reject('Location not supported by your browser.');
      }

      const defaultOptions = {
        maximumAge: Infinity,
        timeout: 2000,
        enableHighAccuracy: false,
      };
      navigator.geolocation.getCurrentPosition(resolve, reject, defaultOptions);
    });

    return {
      altitude: result.coords.altitude || undefined,
      latitude: result.coords.latitude,
      longitude: result.coords.longitude,
    };
  } catch (error) {
    return null;
  }
};
