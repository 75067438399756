import { DENSITY } from 'constants/density.constants';
import { useSettingsStore } from 'store/setting.store';

const useDensity = () => {
  const [settings, updateSettings] = useSettingsStore((state) => [state.settings, state.updateSettings]);

  const handleSetDensity = async (density: DENSITY) => {
    document.body.classList.remove(settings?.asSettings()?.theme as string);
    await updateSettings({ density });
  };

  return { setDensity: handleSetDensity, density: settings?.asSettings()?.density };
};

export default useDensity;
