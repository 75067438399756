import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import EmptyListComponent from 'components/dump-list/EmptyList.component';
import ListSectionComponent from 'components/dump-list/ListSection.component';
import NoSearchMatchesComponent from 'components/dump-list/NoSearchMatches.component';
import { motion } from 'framer-motion';
import useDumps from 'hooks/dumps/useDumps';
import { debounce } from 'lodash';
import { isBefore, isSameDay, subDays, subMonths } from 'date-fns';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button, ContentLayout } from 'components/common/ComponentLibrary';
import { SEARCH_PARAM, getUrlHome, getUrlSettings, getUrlDumps } from 'utilities/urls.utils';
import { ChevronLeftIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import LoadingIndicator from 'components/common/LoadingIndicator';
import { ROUTE_DIRECTION_ENUM, ROUTE_MOTION_VARIANTS } from 'utilities/motion.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import DumpsFilter from 'components/dumps/DumpsFilter/DumpsFilter';
import { filterDumpsBySearchQuery, filterDumpsByTags } from 'utilities/dumps/dumpFilters.utils';
import { DumpInterface } from 'models/dumps/interfaces/DumpInterface';
import { useSearchStore } from 'store/search.store';
import { useTranslation } from 'react-i18next';
import './DumpList.screen.css';

const DumpListScreen = () => {
  useDocumentTitle('Dumps');

  const { t } = useTranslation('translations');

  const searchBarRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  useHotkeys(
    'left',
    (event) => {
      event.preventDefault();
      navigate(getUrlHome(), { state: { previousRoute: getUrlDumps() } });
    },
    [],
  );
  useHotkeys('right', () => navigate(getUrlSettings(), { state: { previousRoute: getUrlDumps() } }), []);
  useHotkeys('meta+k', () => searchBarRef.current?.focus(), []);

  const [sectionedDumps, setSectionedDumps] = useState<{ [section: string]: DumpInterface[] }>({});

  const [searchTerm, setSearchTerm] = useState<string>('');
  const { tags } = useSearchStore();
  const sections = (index: string) => {
    if (searchTerm.length > 0 && index == '0') return searchTerm.startsWith('#') ? searchTerm : 'top hits';
    switch (index) {
      case '0':
        return t('screens.list.pinned');
      case '1':
        return t('screens.list.today');
      case '2':
        return t('screens.list.last7Days');
      case '3':
        return t('screens.list.lastMonth');
      default:
        return t('screens.list.earlier');
    }
  };

  const { dumps, isLoading } = useDumps();
  useEffect(() => {
    const result: { [section: string]: DumpInterface[] } = {};

    dumps
      .filter(filterDumpsByTags(tags))
      .filter(filterDumpsBySearchQuery(searchTerm))
      .forEach((dump) => {
        const dumpTime = new Date(dump.last_edited_at ?? dump.created_at);

        if (dump.is_pinned) {
          result['0'] = [...(result['0'] ?? []), dump];
        } else if (isSameDay(new Date(), dumpTime)) {
          result['1'] = [...(result['1'] ?? []), dump];
        } else if (isBefore(subDays(new Date(), 7), dumpTime)) {
          result['2'] = [...(result['2'] ?? []), dump];
        } else if (isBefore(subMonths(new Date(), 1), dumpTime)) {
          result['3'] = [...(result['3'] ?? []), dump];
        } else {
          result['4'] = [...(result['4'] ?? []), dump];
        }
      });

    setSectionedDumps(result);
  }, [searchTerm, tags, dumps]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchTerm = (query: string) => {
    setSearchTerm(query);
  };

  const debouncedResults = useMemo(() => debounce(handleSearchTerm, 300), []);

  useEffect(() => {
    if (searchParams.has(SEARCH_PARAM)) {
      setSearchTerm(searchParams.get(SEARCH_PARAM)!);
    }

    return () => {
      debouncedResults.cancel();
    };
  }, []);

  useEffect(() => {
    if (searchTerm.trim().length > 0) {
      setSearchParams({ [SEARCH_PARAM]: searchTerm });
    } else {
      searchParams.delete(SEARCH_PARAM);
      setSearchParams(searchParams);
    }
  }, [searchTerm, searchParams]);

  const location = useLocation();
  const isBackwards =
    location.state?.previousRoute === getUrlSettings() || location.state?.previousRoute === 'dump_detail';

  return (
    <motion.div
      custom={{ direction: isBackwards ? ROUTE_DIRECTION_ENUM.BACKWARD : ROUTE_DIRECTION_ENUM.FORWARD }}
      initial='initial'
      animate='in'
      variants={ROUTE_MOTION_VARIANTS}
    >
      <ContentLayout hasHScreen={Object.keys(sectionedDumps).length === 0}>
        <ContentLayout.Top>
          <div className='flex items-center justify-between'>
            <Button size='l' theme='bare' width='none' href={getUrlHome()}>
              <Button.Icon icon={<ChevronLeftIcon className='w-6' />} />
            </Button>
            <Button width='none' size='l' theme='bare' href={getUrlSettings()}>
              <Button.Icon icon={<Cog6ToothIcon className='w-8' />} />
            </Button>
          </div>
        </ContentLayout.Top>
        <ContentLayout.Content>
          <div className='flex flex-col w-full'>
            {Object.keys(dumps).length > 0 && (
              <div className='w-full'>
                <DumpsFilter searchBarRef={searchBarRef} onSearch={handleSearchTerm} searchTerm={searchTerm} />
              </div>
            )}
            {isLoading && <LoadingIndicator className='w-20' />}
            {Object.keys(sectionedDumps).length === 0 &&
              ((searchTerm.length === 0 && <EmptyListComponent />) || (
                <NoSearchMatchesComponent searchTerm={searchTerm} />
              ))}

            {Object.keys(sectionedDumps).map((section) => {
              return (
                <div key={section} className='mb-10'>
                  <ListSectionComponent
                    title={sections(section)}
                    dumps={sectionedDumps[section]}
                    searchTerm={searchTerm}
                  />
                </div>
              );
            })}
          </div>
        </ContentLayout.Content>
      </ContentLayout>
    </motion.div>
  );
};

export default DumpListScreen;
